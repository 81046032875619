import { IconButton, Link, makeStyles } from '@material-ui/core'
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons'
import React, { useState } from 'react'

import { DialogWithClose } from '../dialogs'
import { Heading } from '../typography'

const useStyles = makeStyles(theme => ({
  link: {
    marginTop: theme.spacing(6),
  },
}))

const links = [
  { label: 'Novus Events', href: '/events' },
  { label: 'Partner Benefits', href: '/benefits' },
  { label: 'My Account', href: '/account' },
]

export const MobileMenu = () => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)
  return (
    <>
      <IconButton onClick={handleOpen}>
        <MenuIcon color="primary" />
      </IconButton>
      <DialogWithClose fullScreen={true} open={isOpen} onClose={handleClose}>
        {links.map(({ label, href }, i) => (
          <Heading
            key={i}
            variant="h2"
            underlined={true}
            className={classes.link}
          >
            <Link color="inherit" href={href} onClick={handleClose}>
              {label}
            </Link>
          </Heading>
        ))}
      </DialogWithClose>
    </>
  )
}

export default MobileMenu
