import { Card, Link, List, ListItem, ListItemText } from '@material-ui/core'
import { Link as RouterLink } from '@reach/router'
import PropTypes from 'prop-types'
import React from 'react'

export const SecondaryNav = ({ links }) => {
  return (
    <Card>
      <List>
        {links.map(({ label, to }, i) => (
          <Link
            key={i}
            color="inherit"
            component={RouterLink}
            to={to}
            underline="none"
          >
            <ListItem button>
              <ListItemText>{label}</ListItemText>
            </ListItem>
          </Link>
        ))}
      </List>
    </Card>
  )
}

SecondaryNav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      to: PropTypes.string,
    })
  ),
}

export default SecondaryNav
