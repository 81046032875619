import React from 'react'
import { navigate } from '@reach/router'
import { Container } from '@material-ui/core'
import { useApi, useProfile } from 'novus-common/lib/hooks'
import Heading from '../../typography/Heading'
import SelectPlans from '../../SelectPlans'

export default () => {
  const [profile, setProfile] = useProfile()
  const [_, setRequest, loading] = useApi({
    disableAppLoader: true,
    onSuccess: () => {
      setProfile({
        ...profile,
        selectBenefits: true,
      })
      navigate('/')
    },
  })

  const handleDone = () => {
    if (loading) {
      return
    }

    setRequest({
      method: 'patch',
      path: '/profile/',
      init: {
        body: {
          seen_select_benefits: true,
        },
      },
    })
  }

  return (
    <Container maxWidth="xs">
      <Heading bigGutter underlined variant="h2">
        Select Benefits
      </Heading>

      <SelectPlans onSuccess={handleDone} />
    </Container>
  )
}
