import React, { useState } from 'react'
import { Container, makeStyles } from '@material-ui/core'
import { navigate } from '@reach/router'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import cc from 'camelcase-keys'

import SignUpForm from '../../form/SignUpForm'
import AuthHeader from '../../layout/AuthHeader'
import config from '../../../config'

const useStyles = makeStyles(theme => ({
  header: {
    paddingTop: theme.spacing(3),
  },
}))

const makeRequest = (method, endpoint, ...rest) =>
  axios[method](`${config.api.url}/${endpoint}`, ...rest)

const SignUp = () => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()

  const handleSubmit = async data => {
    setLoading(true)
    try {
      const member = await makeRequest('post', `signup`, {
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        phone_number: data.phoneNumber,
        password: data.password,
      })
      const user = await Auth.signIn(data.email, data.password)

      navigate('/')
    } catch (error) {
      console.log(error)
      if (error.request.status === 400) {
        throw typeof error.response.data === 'object'
          ? {
              fields: cc(error.response.data),
            }
          : {
              form: error.message,
            }
      } else {
        throw error
      }
    } finally {
      setLoading(false)
    }
  }

  const handleBackClick = () => {
    navigate('/')
  }

  return (
    <Container maxWidth="xs">
      <AuthHeader onLogoClick={handleBackClick} />
      <SignUpForm
        loading={loading}
        onSubmit={handleSubmit}
        onBackClick={handleBackClick}
      />
    </Container>
  )
}

export default SignUp
