import React, {
  Fragment,
  useState,
  useEffect,
  createRef,
  useRef,
  useMemo,
} from 'react'
import { Link } from '@reach/router'
import {
  Button,
  Grid,
  Container,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import moment from 'moment'
import debounce from 'debounce'
import scrollToElement from 'scroll-to-element'
import VisibilitySensor from 'react-visibility-sensor'
import { parsers } from 'novus-common/lib/modules/api'
import { useProfile } from 'novus-common/lib/hooks'
import { InfiniteScroller } from 'novus-common/lib/components'
import OfferingCard from '../cards/OfferingCard'
import Section from '../layout/Section'
import Heading from '../typography/Heading'
import Calendar from '../Calendar'

const limit = 10
const scrollDuration = 1000

const createPath = ({ tierId, page }) =>
  `/events/?offset=${page * limit}&limit=${limit}` +
  (tierId ? `&tier=${tierId}` : '')

const groupEventsByMonth = events =>
  events.reduce((acc, event) => {
    const [year, month] = event.date.split('-')
    const key = year + '-' + month

    acc[key] ? acc[key].push(event) : (acc[key] = [event])

    return acc
  }, {})

const getMonthName = date => moment(date).format('MMMM')

const scrollToRef = (offset = 0) => ({ current }, callback) => {
  setTimeout(callback, scrollDuration)
  scrollToElement(current, {
    offset,
    duration: scrollDuration,
  })
}

const getDates = ({ date, items }) => {
  const currentMonth = date.month()

  return items
    .map(({ date }) => moment(date))
    .filter(date => date.month() === currentMonth)
}

const useStylesList = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
  },
}))

const List = ({ items, loading, date, onItemVisible }) => {
  const itemsByMonth = groupEventsByMonth(items)
  const months = Object.keys(itemsByMonth)
  const monthsRef = useRef({})
  const itemsRef = useRef({})
  const theme = useTheme()
  const scrollTo = scrollToRef(-theme.spacing(12))
  const [scrollDetectionDisabled, setScrollDetectionDisabled] = useState(false)
  const classes = useStylesList()

  const handleItemVisible = item => visible =>
    visible && !loading && !scrollDetectionDisabled && onItemVisible(item)

  useEffect(() => {
    const dateFormat = 'YYYY-MM-DD'
    const dateFormatted = date.format(dateFormat)
    const item = items.find(({ date }) => date === dateFormatted)
    let ref = item && itemsRef.current[item.id]

    if (!ref) {
      const yearAndMonth = date.format('YYYY-MM')
      ref = monthsRef.current[yearAndMonth]
    }

    if (!ref && items.length) {
      const { id } = items.slice(-1)[0]
      ref = itemsRef.current[id]
    }

    if (!ref) {
      return
    }

    item && onItemVisible(item)
    setScrollDetectionDisabled(true)
    scrollTo(ref, () => setScrollDetectionDisabled(false))
  }, [date])

  monthsRef.current = useMemo(
    () =>
      months.reduce(
        (refs, month) => ({
          ...refs,
          [month]: createRef(),
        }),
        {}
      ),
    [items]
  )

  itemsRef.current = useMemo(
    () =>
      items.reduce(
        (refs, { id }) => ({
          ...refs,
          [id]: createRef(),
        }),
        {}
      ),
    [items]
  )

  return (
    <Grid container spacing={2}>
      {months.map(yearAndMonth => (
        <Fragment key={yearAndMonth}>
          <Grid item xs={12} ref={monthsRef.current[yearAndMonth]}>
            <Heading variant="h3">{getMonthName(yearAndMonth)}</Heading>
          </Grid>
          {itemsByMonth[yearAndMonth].map(
            ({ memberRegistered, ...item }, i) => (
              <Grid key={i} item xs={12} sm={12} md={6} lg={4}>
                <Link
                  className={classes.link}
                  to={`${item.id}`}
                  ref={itemsRef.current[item.id]}
                >
                  <VisibilitySensor onChange={handleItemVisible(item)}>
                    <OfferingCard
                      {...item}
                      isEvent={true}
                      registered={memberRegistered}
                    />
                  </VisibilitySensor>
                </Link>
              </Grid>
            )
          )}
        </Fragment>
      ))}
    </Grid>
  )
}

const useStylesEvents = makeStyles(theme => ({
  calendar: {
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(12),
      position: 'sticky',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  calendarCol: {
    flex: 0,
    paddingTop: theme.spacing(5),
    width: 350,
  },
  listCol: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
  },
  mobileSwitch: {
    background: 'rgba(0,0,0,0.8)',
    bottom: 0,
    left: 0,
    padding: theme.spacing(2),
    position: 'fixed',
    width: '100%',
    zIndex: 2,
  },
}))

const Content = ({ items, loading, loadMore }) => {
  const [date, setDate] = useState(moment())
  const [dateList, setDateList] = useState(moment())
  const classes = useStylesEvents()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const [viewAsCalendar, setViewAsCalendar] = useState(false)

  const handleCalendarChange = date => {
    !isDesktop && setDate(date)
    setDateList(date)
    setViewAsCalendar(false)
  }

  const handleCalendarMonthChange = date => {
    setDate(date)
    setDateList(date)
  }

  const handleListItemVisible = item => {
    setDate(moment(item.date))
  }

  const handleViewAsCalendar = () => {
    setViewAsCalendar(!viewAsCalendar)
  }

  const handleVisibilitySensorChange = debounce(
    visible => visible && loadMore(),
    100
  )

  const calendar = (
    <Calendar
      className={classes.calendar}
      minDate={moment()}
      date={date}
      selectedDates={getDates({ date, items })}
      onChange={handleCalendarChange}
      onMonthChange={handleCalendarMonthChange}
    />
  )

  const list = (
    <List
      items={items}
      loading={loading}
      date={dateList}
      onItemVisible={handleListItemVisible}
    />
  )

  const visibilitySensor = (
    <VisibilitySensor onChange={handleVisibilitySensorChange}>
      <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
    </VisibilitySensor>
  )

  return isDesktop ? (
    <div className={classes.container}>
      <div className={classes.calendarCol}>{calendar}</div>

      <div className={classes.listCol}>
        {list}
        {visibilitySensor}
      </div>
    </div>
  ) : (
    <>
      <div className={classes.mobileSwitch}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={handleViewAsCalendar}
        >
          {viewAsCalendar ? 'View As List' : 'View As Calendar'}
        </Button>
      </div>

      {viewAsCalendar ? (
        calendar
      ) : (
        <>
          {list}
          {visibilitySensor}
        </>
      )}
    </>
  )
}

const Events = () => (
  <Container>
    <Section title="Events">
      <InfiniteScroller
        createPath={page => createPath({ page, tierId: 'all' })}
        useApiParams={{ parse: parsers.parseEvents }}
      >
        {({ items, loading, loadMore }) => (
          <Content
            {...{
              items,
              loading,
              loadMore,
            }}
          />
        )}
      </InfiniteScroller>
    </Section>
  </Container>
)

export default Events
