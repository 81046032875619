import { Link, makeStyles } from '@material-ui/core'
import React from 'react'
import { Link as RouterLink } from '@reach/router'

import logo from './logo.svg'

const useStyles = makeStyles(theme => ({
  logo: {
    cursor: 'pointer',
    height: 100,
    width: 100,
  },
  root: {
    paddingBottom: 50,
    paddingTop: 50,
    textAlign: 'center',
  },
}))

export const AuthHeader = ({ loading = false, onLogoClick = () => {} }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img src={logo} className={classes.logo} onClick={onLogoClick} />
    </div>
  )
}

export default AuthHeader
