import React, { useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { parsers } from 'novus-common/lib/modules/api'
import { useApp, useApiContext, useProfile } from 'novus-common/lib/hooks'
import OfferingOverviewCard from '../cards/OfferingOverviewCard'
import Button from '../form/Button'
import TabPanel from './TabPanel'

const useStyles = makeStyles(theme => ({
  root: {},
  footer: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2),
      paddingTop: 0,
    },
  },
}))

export default ({ plans, onSuccess, ...props }) => {
  const app = useApp()
  const api = useApiContext()
  const [profile, setProfile] = useProfile()
  const [selection, setSelection] = useState({})
  const classes = useStyles()

  const handlePlanClick = ({ id, subscriptionPrice }) => {
    setSelection({
      ...selection,
      [id]: selection[id] ? null : { id, subscriptionPrice },
    })
  }

  const handleSubmit = async () => {
    app.setLoading(true)

    try {
      debugger
      const tasks = Object.values(selection).map(({ id }) =>
        api.del('default', `/benefits/${id}/subscribe/`, {})
      )

      await Promise.all(tasks)

      app.displaySnackMessage('Benefits successfully updated', 'success')
      setProfile(parsers.parseProfile(await api.get('default', '/profile/')))
      setSelection({})
      onSuccess && onSuccess()
    } catch (error) {
      console.error(error)
      app.setError({ message: 'Could not update Benefits' })
    }

    app.setLoading(false)
  }

  const selectedPlans = Object.values(selection).filter(item => !!item)

  return (
    <TabPanel {...props}>
      <Grid container spacing={2}>
        {!plans.length && 'No subscribed benefits'}

        {plans.map(({ id, title, partner, subscriptionPrice }, i) => (
          <Grid key={i} item xs={12}>
            <OfferingOverviewCard
              title={title}
              partner={`${partner} ($${subscriptionPrice})`}
              selected={!!selection[id]}
              onClick={() => handlePlanClick({ id, subscriptionPrice })}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2} className={classes.footer}>
        <Grid item xs={12}>
          <Button
            color="primary"
            disabled={selectedPlans.length < 1 || app.loading}
            fullWidth
            loading={app.loading}
            onClick={handleSubmit}
            variant="contained"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </TabPanel>
  )
}
