import { Card, CardContent, Typography, makeStyles } from '@material-ui/core'
import { CreditCard as CreditCardIcon } from '@material-ui/icons'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { Heading } from '../typography'

const useStyles = makeStyles(theme => ({
  cardDetails: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  cardIcon: {
    marginRight: theme.spacing(0.5),
  },
  memberDetails: {
    [theme.breakpoints.up('sm')]: {
      flexGrow: 1,
    },
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  root: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 700,
    },
  },
}))

export const ProfileCard = ({
  className,
  cardType,
  cardLast4,
  email,
  firstName,
  lastName,
  tierExpiry,
}) => {
  const classes = useStyles()
  const cardInfo = cardLast4
    ? `${cardType} ending in ${cardLast4}`
    : `no card saved`

  return (
    <Card className={classNames(classes.root, className)}>
      <CardContent className={classes.content}>
        <div className={classes.memberDetails}>
          <Heading variant="h2" gutterBottom>
            {firstName} {lastName}
          </Heading>
          <Typography color="textSecondary">{email}</Typography>
          <Typography color="textSecondary" className={classes.cardDetails}>
            <CreditCardIcon fontSize="small" className={classes.cardIcon} />{' '}
            {cardInfo}
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}

const formatNum = numStr => numStr.toLocaleString()

ProfileCard.propTypes = {
  className: PropTypes.string,
  cardType: PropTypes.string,
  cardLast4: PropTypes.string,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
}

export default ProfileCard
