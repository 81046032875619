import React from 'react'
import { parsers } from 'novus-common/lib/modules/api'
import { useApiGetWithProfileData } from 'novus-common/lib/hooks'
import OfferingSection from './OfferingSection'

const limit = 10

const EventsSection = () => {
  const [data] = useApiGetWithProfileData({
    path: `/events/?tier=all&limit=${limit}`,
    parse: parsers.parseEvents,
  })

  if (!data) {
    return null
  }

  return (
    <OfferingSection
      allLink={{ href: '/events', label: 'See All Events' }}
      createDetailsLink={id => `/events/${id}`}
      data={data.items}
      emptyText="No events at the moment"
      isEvent={true}
      title="Novus Events"
    />
  )
}

export default EventsSection
