import React from 'react'
import { Container } from '@material-ui/core'
import { ForgotPassword as BaseForgotPassword } from 'aws-amplify-react'
import { Hub } from 'aws-amplify'
import { useApp } from 'novus-common/lib/hooks'
import withHooks from 'novus-common/lib/withHooks'
import ForgotPasswordForm from '../form/ForgotPasswordForm'
import AuthHeader from '../layout/AuthHeader'
import createInputChangeEvent from './createInputChangeEvent'

const initialState = {
  username: '',
  error: null,
  codeSent: false,
  delivery: null,
}

class ForgotPassword extends BaseForgotPassword {
  constructor(props) {
    super(props)

    this._validAuthStates = ['forgotPassword']
    this.state = {
      ...this.state,
      ...initialState,
    }
  }

  componentDidMount = () => {
    Hub.listen('auth', this.handleAuthEvent)
  }

  componentWillUnmount = () => {
    Hub.remove('auth', this.handleAuthEvent)
  }

  handleAuthEvent = ({ payload }) => {
    const { displaySnackMessage } = this.props.app
    const { event, data, message } = payload

    if (event === 'forgotPassword') {
      this.state.codeSent
        ? displaySnackMessage(message, 'success')
        : this.setState({ codeSent: true })
    }

    event === 'forgotPasswordSubmit' &&
      displaySnackMessage('Password successfully changed!', 'success')

    this.setState({
      error: event === 'forgotPassword_failure' ? data : null,
    })
  }

  handleSendClick = ({ username }) => {
    username ? this.setState({ username }, this.send) : this.send()
  }

  handleSubmitClick = async data => {
    this.setState({ error: null })

    Object.entries(data)
      .map(createInputChangeEvent)
      .forEach(this.handleInputChange)

    await this.submit()
  }

  handleBackClick = () => {
    this.changeState('signIn')
    this.setState(initialState)
  }

  getUsernameFromInput = () => this.state.username

  showComponent = () => (
    <Container maxWidth="xs">
      <AuthHeader onLogoClick={this.handleBackClick} />
      <ForgotPasswordForm
        codeSent={!!this.state.delivery}
        onSendClick={this.handleSendClick}
        onSubmitClick={this.handleSubmitClick}
        onBackClick={this.handleBackClick}
        error={this.state.error && { form: this.state.error.message }}
      />
    </Container>
  )
}

export default withHooks(useApp, 'app')(ForgotPassword)
