import React from 'react'
import { makeStyles } from '@material-ui/core'

import { useProfile } from 'novus-common/lib/hooks'

import ProfileCard from './cards/ProfileCard'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
  },
}))

export default ({ className }) => {
  const [profile] = useProfile()
  const classes = useStyles()

  if (!profile) {
    return null
  }

  const { firstName, lastName, email, paymentMethod } = profile

  return (
    <ProfileCard
      cardType={paymentMethod && paymentMethod.card.brand}
      cardLast4={paymentMethod && paymentMethod.card.last4}
      className={className}
      email={email}
      firstName={firstName}
      lastName={lastName}
    />
  )
}
