import { Chip, makeStyles } from '@material-ui/core'
import { LocalActivity as LocalActivityIcon } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'

const plural = (num, text) => (num == 1 ? `1 ${text}` : `${num} ${text}s`)

const useStyles = makeStyles(theme => ({
  capacity: {
    bottom: theme.spacing(1),
    position: 'absolute',
    right: theme.spacing(1),
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
}))

const CapacityChip = ({ capacity, registrationCount }) => {
  const classes = useStyles()
  const soldOut = registrationCount == capacity
  const placesLeft = capacity - registrationCount
  const runningLow = placesLeft < 20

  const icon = <LocalActivityIcon className={classes.icon} fontSize="small" />

  if (soldOut) {
    return (
      <Chip
        className={classes.capacity}
        color="primary"
        icon={icon}
        label="Event Sold Out"
      />
    )
  } else if (runningLow) {
    return (
      <Chip
        className={classes.capacity}
        color="primary"
        icon={icon}
        label={`Only ${plural(placesLeft, 'space')} left`}
      />
    )
  } else {
    return null
  }
}

CapacityChip.propTypes = {
  capacity: PropTypes.number.isRequired,
  registrationCount: PropTypes.number.isRequired,
}

export default CapacityChip
