import React from 'react'
import { parsers } from 'novus-common/lib/modules/api'
import { useApiGetWithProfileData } from 'novus-common/lib/hooks'
import OfferingSection from './OfferingSection'

const limit = 10

const RegisteredEventsSection = () => {
  const [data] = useApiGetWithProfileData({
    path: `/events/?tier=all&registered`,
    parse: parsers.parseEvents,
  })

  if (!data || !data.items.length) {
    return null
  }

  return (
    <OfferingSection
      allLink={{ href: '' }}
      createDetailsLink={id => `/events/${id}`}
      data={data.items}
      isEvent={true}
      title="Your upcoming Events"
      emptyText="You are not registered for any upcoming events..."
    />
  )
}

export default RegisteredEventsSection
