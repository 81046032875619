import { Container, Link, Typography, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from '@reach/router'

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  copyright: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  link: {
    margin: theme.spacing(0, 1, 1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  links: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  root: {
    backgroundColor: '#2B2B2B',
    borderTop: '2px solid',
    borderTopColor: theme.palette.primary.main,
    marginTop: theme.spacing(4),
  },
}))

const links = [
  { label: 'Contact Us', href: '/contact' },
  { label: 'Terms Of Use', href: '/terms' },
  { label: 'Privacy Policy', href: '/privacy' },
]

export const Footer = ({ className }) => {
  const classes = useStyles()
  return (
    <div className={classNames(classes.root, className)}>
      <Container className={classes.container}>
        <Typography
          color="textSecondary"
          variant="body2"
          className={classes.links}
        >
          {links.map(({ label, href }, i) => (
            <Link
              key={i}
              className={classes.link}
              color="inherit"
              component={RouterLink}
              to={href}
            >
              {label}
            </Link>
          ))}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
          className={classes.copyright}
        >
          Copyright Novus
        </Typography>
      </Container>
    </div>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

export default Footer
