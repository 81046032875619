import React from 'react'
import { Container, makeStyles } from '@material-ui/core'

import Heading from '../typography/Heading'

const useStyles = makeStyles(theme => ({
  heading: {
    paddingTop: theme.spacing(3),
  },
}))

const Terms = () => {
  const classes = useStyles()
  return (
    <Container maxWidth="md">
      <Heading
        bigGutter={true}
        underlined
        variant="h2"
        className={classes.heading}
      >
        Terms Of Use
      </Heading>
      <div>
        <h3>1. Introduction</h3>
        <p>
          1.1 <strong>Background. </strong>These User Terms and Conditions (the{' '}
          <strong>Terms</strong>) govern the contractual relationship between
          Berkeley Assets Limited (<strong>we </strong>or <strong>us</strong>)
          and the individual making use of the Novus membership platform. The
          service can be accessed through the Novus website at{' '}
          <a>https://novus-ba.com/</a>
        </p>
        <p>
          1.2 (The <strong>Website</strong>) or via our iOS and Android mobile
          app (the <strong>App</strong>). The related online documentation is
          referred to as Documentation.
        </p>
        <p>
          1.3 <strong>Important definitions.</strong> The Website, the App, the
          Documentation and any other services that we make available for you to
          use are collectively referred to as the <strong>Service</strong>. The
          term "the Service&rdquo; also refers to any of those constituent
          elements.
        </p>
        <p>
          1.4 <strong>Application of Apple and Google Apps rules. </strong>The
          ways in which you can use the App and related documentation may also
          be controlled by Apple and Google Apps rules and policies. In the
          event of any conflict between these Terms and the Apple and Google
          Apps rules and policies, the Apple and Google Apps rules and policies
          will apply.
        </p>
        <h3>2. Eligibility to use the Service</h3>
        <p>
          2.1 <strong>Age restrictions. </strong>The Service is only intended
          for use by private individuals aged 18 or over.
        </p>
        <p>
          2.2 <strong>Accuracy of information. </strong>When setting up your
          account, you must provide information that is complete, accurate and
          up-to-date. You must also keep the information in your account
          regularly updated. We may take reasonable steps to verify the
          information that you provide.
        </p>
        <h3>3. Purchases & Subscriptions</h3>
        <p>
          3.1 <strong>One off payments. </strong>Included with your membership
          tier will be a usable attendance quota for events and experiences. If
          you wish to attend events and experiences outside of your quota, a one
          off fee will be applied. This fee will be communicated via the site /
          app and will only be applied if you choose to register for the event
          or experience in question.
        </p>
        <p>
          3.2 <strong>Recurring payments. </strong> Included with your
          membership tier will be a number of redeemable benefits, such as
          discounts at popular stores. If you wish to make use of a benefit
          outside of your tier, an annual fee will apply. Your subscription to
          the benefot will continue indefinitely unless you cancel through the
          relevant app store in subscription settings or cancelling via
          contacting us about the subscription in accordance with these Terms.
          You agree that your membership will be subject to automatic annual
          renewal on the basis set out in this Section 3 (Subscription fees) and
          that the annual subscription fees will be automatically charged and
          taken without further reference back to you until the subscription is
          cancelled.
        </p>
        <p>
          3.4 <strong>Changes to recurring payments. </strong>We may revise the
          monthly subscription fee by giving you as much notice as possible via
          the Website or the App. This is in order to give you the opportunity
          to cancel your subscription before the fee change takes effect, should
          you wish to do so. If you continue to use the Service after any such
          increase takes effect, you will be deemed to have agreed to the
          increase.
        </p>
        <p>
          3.5 <strong>No refunds. </strong>Except where clearly stated in these
          Terms, monthly subscription fees will not be refunded once they have
          been paid. In particular, if you choose to terminate part way through
          a month, there will be no refund of subscription fees for the
          unexpired portion of the relevant month.
        </p>
        <h3>4. Cancellation</h3>
        <p>
          4.4 <strong>Recurring payment cancellation. </strong>
          You may cancel any recurring payments on 7 days' notice at any time by
          sending a notice of cancellation to us at{' '}
          <a data-span="hyperlink">support@novus-ba.com</a>.
        </p>
        <p>
          4.4 <strong>Cancellation by us without cause. </strong>We may cancel
          your membership by contacting you, without giving reasons, on 3 days'
          notice at any time. If that 3-day period expires after your next
          installment of monthly subscription fees is due, we will not take the
          relevant subscription fees.
        </p>
        <p>
          4.5 <strong>Cancellation by us with cause. </strong>We may also cancel
          your membership, with immediate effect, by contacting you if you have
          failed to comply with your obligations under these Terms in a serious
          way. If what you have done can be put right, we will give you a
          reasonable opportunity to do so.
        </p>
        <p>
          4.6 <strong>Consequences of cancellation. </strong>If your
          subscription is cancelled (whether by you or by us):
        </p>
        <p>
          4.6.1 you must stop all activities authorised by these Terms,
          including your use of the Website, the App and any other elements of
          the Service;
        </p>
        <p>
          4.6.2 you must delete or remove the App from all devices in your
          possession and immediately destroy all copies of the App which you
          have, and confirm to us that you have done this;
        </p>
        <p>
          4.6.3 we may remotely access your devices and remove the App from them
          and cease providing you with access to the Services; and
        </p>
        <p>
          4.6.4 any information included in your profile, and any messages sent
          to other members, will be deleted.
        </p>
        <h3>5. Rights and restrictions on your use of the Service</h3>
        <p>
          5.1{' '}
          <strong>Things that you are allowed to do using the Service. </strong>
          In return for your agreeing to comply with these Terms (including the
          restrictions set out in paragraphs 5.2 and 5.3 below), you may:
        </p>
        <p>
          5.1.1 download or stream a copy of the App onto 5 mobile telephone or
          handheld devices and view, use and display the Service on such devices
          for your personal purposes only;
        </p>
        <p>
          5.1.2 use any Documentation to support your permitted use of the App
          and the Service;
        </p>
        <p>
          5.1.3 receive and use any free supplementary software code or update
          of the App incorporating "patches" and corrections of errors as we may
          provide to you.
        </p>
        <p>
          5.2{' '}
          <strong>
            Things that you are not allowed to do using the Service &ndash;
            license restrictions.{' '}
          </strong>
          You agree:
        </p>
        <p>
          5.2.1 that you will not rent, lease, sub-license, loan, provide, or
          otherwise make available, the App or the Service in any form, in whole
          or in part, to any person without prior written consent from us
          (except in accordance with paragraph 5.1 above);
        </p>
        <p>
          5.2.2 that you will not copy the App or the Service, except as part of
          the normal use of the Service or where it is necessary for the purpose
          of back-up or operational security;
        </p>
        <p>
          5.2.3 that you will not translate, merge, adapt, vary, alter or
          modify, the whole or any part of the App, the Documentation or the
          Service nor permit them or any part of them to be combined with, or
          become incorporated in, any other programs, except as necessary to use
          the App and the Service on devices as permitted in these Terms;
        </p>
        <p>
          5.2.4 That you will not disassemble, de-compile, reverse engineer or
          create derivative works based on the whole or any part of the App or
          the Service nor attempt to do any such things, except to the extent
          that such actions cannot be prohibited as a matter of law;
        </p>
        <p>
          5.2.5 that you will comply with all applicable technology control or
          export laws and regulations that apply to the technology used or
          supported by the App or other aspects of the Service;
        </p>
        <p>
          5.2.6 that if you download or stream the App onto any phone or other
          devices not owned by you, you must have the owner&rsquo;s permission
          to do so, and you agree to be responsible for complying with these
          Terms, whether or not you own the phone or other devices on which you
          are using the Service.
        </p>
        <p>
          5.3{' '}
          <strong>
            Things that you are not allowed to do using the Service &ndash;
            acceptable use restrictions.{' '}
          </strong>
          You agree:
        </p>
        <p>
          5.3.1 that you will not use the Service in any unlawful manner, for
          any unlawful purpose, or in any manner inconsistent with these terms,
          or act fraudulently or maliciously, for example, by hacking into or
          inserting malicious code, such as viruses, or harmful data, into the
          Service or any operating system;
        </p>
        <p>
          5.3.2 that you will not infringe our intellectual property rights or
          those of any third party in relation to your use of the Service,
          including by the submission of any material (to the extent that such
          use is not licensed by these Terms);
        </p>
        <p>
          5.3.3 that you will not transmit any material that is defamatory,
          offensive or otherwise objectionable in relation to your use of the
          Service;
        </p>
        <p>
          5.3.4 that you will not use the Service in a way that could damage,
          disable, overburden, impair or compromise our systems or security or
          interfere with other users; and
        </p>
        <p>
          5.3.5 that you will not collect or harvest any information or data
          from the Service or our systems or attempt to decipher any
          transmissions to or from the servers running the Service.
        </p>
        <h3>6. How we use your data</h3>
        <p>
          6.1 <strong>Personal data. </strong>We only use personal data we
          collect through your use of the Service in the ways set out in our
          Privacy Notice which you can access here:{' '}
          <a data-span="hyperlink">https://novus-ba.com/privacy</a>
        </p>
        <p>
          6.2 <strong>Technical data. </strong>By using the App or any part of
          the Service, you agree to us collecting and using technical
          information about the devices you use the App on and related software,
          hardware and peripherals, in order to improve our products and to
          provide the Service to you.
        </p>
        <p>
          6.3 <strong>Location data. </strong>Certain parts of the Service will
          make use of location data sent from your devices. You can turn off
          this functionality and stop us from collecting such data at any time
          by turning off the location services settings for the App on the
          device. If you use these parts of the Service, you consent to us and
          our affiliates&rsquo; and licensees&rsquo; transmission, collection,
          retention, maintenance, processing and use of your location data and
          queries to provide and improve location-based and road traffic-based
          products and services.
        </p>
        <h3>7. Our responsibility for loss or damage suffered by you</h3>
        <p>
          7.1{' '}
          <strong>
            We do not exclude or limit in any way our liability to you where it
            would be unlawful to do so
          </strong>
          . This includes liability for death or personal injury caused by our
          negligence or the negligence of our employees, agents or
          subcontractors or for fraud or fraudulent misrepresentation.
        </p>
        <p>
          7.2{' '}
          <strong>
            We are responsible to you for foreseeable loss and damage caused by
            us.
          </strong>{' '}
          If we fail to comply with these Terms, we are responsible for loss or
          damage you suffer that is a foreseeable result of our breaking these
          Terms or our failing to use reasonable care and skill. However, we are
          not responsible for any loss or damage that is not foreseeable. Loss
          or damage is foreseeable if either it is obvious that it will happen
          or if, at the time you accepted these Terms, both we and you knew it
          might happen.
        </p>
        <p>
          7.3{' '}
          <strong>
            We are responsible for damage to your property in certain
            situations.
          </strong>{' '}
          If defective digital content that we have supplied damages a device or
          digital content belonging to you, we will either repair the damage or
          pay you compensation. However, we will not be liable for damage that
          you could have avoided by following our advice to apply an update
          offered to you free of charge or for damage that was caused by you
          failing to correctly follow installation instructions or to have in
          place the minimum system requirements advised by us. You can find the
          minimum system requirements for the App through the app store.
        </p>
        <p>
          7.4 <strong>We are not responsible for business losses.</strong> The
          Service is intended for private and personal use only. If you use the
          Service for any commercial, business or resale purpose we will have no
          liability to you for any loss of profit, loss of business, business
          interruption, or loss of business opportunity.
        </p>
        <p>
          7.5{' '}
          <strong>We are not responsible for other websites you link to</strong>
          . The Service may contain links to other independent websites which
          are not provided by us. Such independent sites are not under our
          control, and we are not responsible for and have not checked and
          approved their content or their privacy policies (if any). You will
          need to make your own independent judgment about whether to use any
          such independent sites, including whether to buy any products or
          services offered by them.
        </p>
        <p>
          7.6{' '}
          <strong>
            We are not responsible for events outside our control.
          </strong>{' '}
          If our provision of the Service or support for the Website or the App
          is delayed by an event outside our control, then we will contact you
          as soon as possible to let you know and we will take steps to minimise
          the effect of the delay. Provided we do this, we will not be liable
          for delays caused by the event but if there is a risk of substantial
          delay you may contact us to end your membership with us and receive a
          refund for the unexpired portion of any month for which you have
          pre-paid the monthly subscription fees.
        </p>
        <p>
          7.7{' '}
          <strong>
            You are responsible for using the Service in a prudent and
            reasonable manner.{' '}
          </strong>
          The Service is provided for general information and entertainment
          purposes only and has not been developed to meet your individual
          requirements. Please also be aware that internet transmissions are
          never completely private or secure and that any message or information
          you send using the Service may be read or intercepted by others, even
          if there is a special notice that a particular transmission is
          encrypted. We recommend that you check the facilities and functions of
          the Service (as described on the Appstore site and in the
          Documentation) to ensure that it meets your requirements, and that you
          exercise caution, judgment and common sense when using the Service,
          and in particular when sharing information or photographs about
          yourself or making arrangements to meet other users. We make no
          representations, warranties or guarantees, whether express or implied,
          that the information provided by members is accurate, complete or up
          to date.
        </p>
        <p>
          7.8{' '}
          <strong>
            You are responsible for backing up the content and data that you use
            with the Service.{' '}
          </strong>
          We recommend that you back up any content and data used in connection
          with the Service, to protect yourself in case of problems with the
          Service.
        </p>
        <h3>8. Changes</h3>
        <p>
          8.1 <strong>Changes to these Terms</strong>. We may need to change
          these Terms to reflect changes in law or best practice, or to deal
          with additional features which we introduce. We will give you as much
          notice as possible of any change by notifying you of a change when you
          next use the App or the Website via notification through the relevant
          app store. If you do not accept the changes that we have notified, you
          will not be permitted to continue to use the Service and you may apply
          [to the Appstore] for a refund of the unexpired portion of any
          pre-paid monthly subscription fees, which will reflect the period the
          Service has been available to you prior to cancellation.
        </p>
        <p>
          8.2{' '}
          <strong>
            Changes to the Service (including updates to the App or the Website)
          </strong>
          . From time to time we may automatically update the App or the
          Website, or change the Service to improve performance, enhance
          functionality, reflect changes to the operating system or address
          security issues. Alternatively, we may ask you to update the App for
          these reasons. If you choose not to install such updates or if you opt
          out of automatic updates you may not be able to continue using the
          Service. (The App will always work with the current or previous
          version of the operating system (as it may be updated from time to
          time) and match the description of it provided to you when you first
          became a member.)
        </p>
      </div>
      <h3>9. Intellectual property rights</h3>
      <p>
        9.1 <strong>Ownership of intellectual property rights. </strong>All
        intellectual property rights in the App, the Website, the Documentation,
        and the Service generally, throughout the world, belong to us (or our
        licensors) and all such rights are licensed (not sold) to you, subject
        to your compliance with these Terms.
      </p>
      <p>
        9.2{' '}
        <strong>No transfer of Intellectual Property Rights to you. </strong>You
        have no intellectual property rights in or to the App, the Website, the
        Documentation or any other aspect of the Service, other than the right
        to use them in accordance with these Terms.
      </p>
      <h3>10. General</h3>
      <p>
        10.1 <strong>You may not transfer the Service to someone else</strong>.
        These Terms give you the right personally to use the Service as set out
        above. Apart from the sharing rights stated in these Terms, you may not
        otherwise transfer the Service (or your rights and obligations under
        these Terms) to someone else, whether for money, for anything else or
        for free. If you sell any device on which the Service is installed, you
        must remove the Service from it.
      </p>
      <p>
        10.2{' '}
        <strong>We may transfer our agreement with you to someone else</strong>.
        We may transfer our rights and obligations under these Terms to another
        organisation. We will always tell you in writing if this happens and we
        will ensure that the transfer will not affect your rights under our
        contract with you.
      </p>
      <p>
        10.3 <strong>No rights for third parties</strong>. These Terms do not
        give rise to any rights under the Contracts (Rights of Third Parties)
        Act 1999 to enforce any term of our contract with you.
      </p>
      <p>
        10.4{' '}
        <strong>
          If a court finds part of this contract illegal, the rest will continue
          in force
        </strong>
        . Each paragraph of these Terms operates separately from the others. If
        any court or relevant authority decides that any of them are unlawful,
        the remaining paragraphs will remain in full force and effect.
      </p>
      <p>
        10.5{' '}
        <strong>
          Even if we delay in enforcing this contract, we can still enforce it
          later
        </strong>
        . Even if we delay in enforcing our contract with you, we can still
        enforce it later. If we do not insist immediately that you do anything
        you are required to do under these Terms, or if we delay in taking steps
        against you in respect of your non-compliance with them, that will not
        mean that you do not have to do those things and it will not prevent us
        from taking steps against you at a later date.
      </p>
      <p>
        10.6{' '}
        <strong>
          Which laws apply to this contract and where you may bring legal
          proceedings
        </strong>
        . These Terms are governed by English law and you can bring legal
        proceedings in respect of the products in the English courts. If you
        live in Scotland, you can bring legal proceedings in respect of the
        products in either the Scottish or the English courts. If you live in
        Northern Ireland, you can bring legal proceedings in respect of the
        products in either the Northern Irish or the English courts.
      </p>
      <p>
        10.7 <strong>Alternative dispute resolution</strong>. Alternative
        dispute resolution is a process where an independent body considers the
        facts of a dispute and seeks to resolve it, without you having to go to
        court. If you are not happy with how we have handled any complaint, you
        may want to contact an alternative dispute resolution provider we use.
        In addition, please note that disputes may be submitted for online
        resolution to the European Commission Online Dispute Resolution
        platform.
      </p>
      <h3>11. Contact arrangements</h3>
      <p>
        11.1 <strong>How you can communicate with us. </strong>If you think that
        the Service is faulty or misdescribed, or wish to contact us for any
        other reason, please email our customer service team at{' '}
        <a>support@novus-ba.com</a>
      </p>
      <p>
        11.2 <strong>How we will communicate with you</strong>. If we have to
        contact you individually, we will do so by email using the relevant
        details that you have most recently provided via the App or the Website.
        We may also post general updates about the Terms or the Service on the
        App or the Website.
      </p>
    </Container>
  )
}

export default Terms
