import React from 'react'
import { Typography, Box } from '@material-ui/core'

export const a11yProps = index => ({
  id: `select-plans-tab-${index}`,
  'aria-controls': `select-plans-tabpanel-${index}`,
})

export default ({ children, value, index, ...props }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`select-plans-tabpanel-${index}`}
    aria-labelledby={`select-plans-tab-${index}`}
    {...props}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </Typography>
)
