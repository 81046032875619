import React from 'react'
import { Grid, Container, Typography, makeStyles } from '@material-ui/core'
import { Link } from '@reach/router'
import debounce from 'debounce'
import VisibilitySensor from 'react-visibility-sensor'
import { parsers } from 'novus-common/lib/modules/api'
import { useProfile, useApiGetWithProfileData } from 'novus-common/lib/hooks'
import { InfiniteScroller } from 'novus-common/lib/components'

import OfferingCard from '../cards/OfferingCard'
import Section from '../layout/Section'

const useStyles = makeStyles(theme => ({
  emptyContainer: {
    width: '100%',
  },
  emptyText: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  link: {
    textDecoration: 'none',
  },
}))

const limit = 10

const createPath = ({ tierId, page }) =>
  `/benefits/?offset=${page * limit}&limit=${limit}` +
  (tierId ? `&tier=${tierId}` : '')

const Content = ({ items, loading, loadMore }) => {
  const classes = useStyles()

  const handleVisibilitySensorChange = debounce(
    visible => visible && loadMore(),
    100
  )

  const visibilitySensor = (
    <VisibilitySensor onChange={handleVisibilitySensorChange}>
      <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
    </VisibilitySensor>
  )

  if (!loading && !items.length) {
    return (
      <div className={classes.emptyContainer}>
        <Typography color="textSecondary" className={classes.emptyText}>
          Exclusive range of partner benefits coming soon...
        </Typography>
      </div>
    )
  }

  return (
    <>
      {items.map((item, i) => (
        <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
          <Link className={classes.link} to={`${item.id}`}>
            <OfferingCard {...item} />
          </Link>
        </Grid>
      ))}
      {visibilitySensor}
    </>
  )
}

const Benefits = () => {
  const [profileData] = useProfile()

  if (!profileData) {
    return null
  }

  return (
    <Container>
      <Section title="Benefits">
        <Grid container spacing={2}>
          <InfiniteScroller
            createPath={page => createPath({ page, tierId: 'all' })}
            useApi={useApiGetWithProfileData}
            useApiParams={{ parse: parsers.parseBenefits }}
          >
            {Content}
          </InfiniteScroller>
        </Grid>
      </Section>
    </Container>
  )
}

export default Benefits
