import { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useProfile } from 'novus-common/lib/hooks'

const Logout = ({ navigate }) => {
  const [_, setProfile] = useProfile()

  useEffect(() => {
    setProfile()
    Auth.signOut().finally(() => navigate('/'))
  })

  return null
}

export default Logout
