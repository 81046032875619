import { Container } from '@material-ui/core'
import { useNavigate } from '@reach/router'
import React, { useEffect } from 'react'

import { parsers } from 'novus-common/lib/modules/api'
import {
  useApiGetWithProfileData,
  useRegisterInterest,
} from 'novus-common/lib/hooks'
import OfferingDetailCard from '../cards/OfferingDetailCard'

const Benefit = ({ id, navigate }) => {
  const path = `/benefits/${id}/`
  const [data] = useApiGetWithProfileData({
    path,
    parse: parsers.parseBenefit,
  })
  const [
    interested,
    registerInterestLoading,
    toggleInterested,
    setInterested,
  ] = useRegisterInterest(path)
  const nav = useNavigate()

  const handleRegisterInterest = () => {
    toggleInterested()
  }

  useEffect(() => data && setInterested(data.interested), [data])

  if (!data) {
    return null
  }

  const cardProps = {
    ...data,
    interested,
    onClickBack: () => nav(-1),
    onRegisterInterest: handleRegisterInterest,
    registerInterestLoading,
  }

  return (
    <Container maxWidth="sm">
      <OfferingDetailCard {...cardProps} />
    </Container>
  )
}

export default Benefit
