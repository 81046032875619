import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core'

import { useApp, useApi } from 'novus-common/lib/hooks'

import DialogWithClose from '../../dialogs/DialogWithClose'
import Button from '../../form/Button'
import Heading from '../../typography/Heading'

const useStyles = makeStyles(theme => ({
  bottomMargin: {
    display: 'block',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingBottom: theme.spacing(3),
  },
}))

export default ({ data, onSuccess, onClose, ...props }) => {
  const [response, setRequest, loading, error, reset] = useApi({
    disableAppLoader: true,
    onSuccess: () => {
      displaySnackMessage(
        'Successfully cancelled registration for event!',
        'success'
      )
      onSuccess && onSuccess()
    },
  })
  const { displaySnackMessage } = useApp()
  const classes = useStyles()
  const { id } = data

  const handleCancelClick = () => {
    setRequest({
      method: 'del',
      path: `/events/${id}/register/`,
      init: {
        response: true,
        body: {},
      },
    })
  }

  const handleCloseClick = () => {
    if (loading) {
      return
    }

    reset()
    onClose && onClose()
  }

  const renderForm = () => (
    <>
      <Heading variant="h3" className={classes.bottomMargin}>
        Cancel Registration
      </Heading>

      <Typography className={classes.bottomMargin}>
        Are you sure you want to cancel your registration?
      </Typography>

      <Button
        color="primary"
        disabled={loading}
        loading={loading}
        onClick={handleCancelClick}
        variant="contained"
      >
        Cancel Registration
      </Button>
    </>
  )

  const renderSuccess = () => (
    <>
      <Heading variant="h3" className={classes.bottomMargin}>
        Registration Cancelled
      </Heading>

      <Typography className={classes.bottomMargin}>
        Your event registration has been cancelled. Any costs have been refunded
        and quotas restored.
      </Typography>

      <Button color="primary" onClick={handleCloseClick} variant="contained">
        Close
      </Button>
    </>
  )

  return (
    <DialogWithClose
      {...props}
      onClose={onClose}
      className={classes.root}
      maxWidth="xs"
    >
      {response ? renderSuccess() : renderForm()}
    </DialogWithClose>
  )
}
