import React from 'react'
import { Link as RouterLink } from '@reach/router'
import {
  Grid,
  Link,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import OfferingCard from '../cards/OfferingCard'
import Button from '../form/Button'
import Section from '../layout/Section'

const useStyles = makeStyles(theme => ({
  carousel: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
  dotGroup: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1.5),
    '& button': {
      display: 'block',
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.75),
      backgroundColor: theme.palette.primary.grey,
      borderRadius: '50%',
      borderWidth: 0,
      '&.carousel__dot--selected': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  list: {
    display: 'flex',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  offeringCard: {
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      height: 325,
    },
  },
  slideInner: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

const Carousel = ({ items, children }) => {
  const classes = useStyles()

  return (
    <CarouselProvider
      naturalSlideWidth={382}
      naturalSlideHeight={325}
      totalSlides={items.length}
      className={classes.carousel}
    >
      <Slider>
        {items.map((item, i) => (
          <Slide
            key={i}
            index={i}
            className={classes.slide}
            innerClassName={classes.slideInner}
          >
            {children({ item })}
          </Slide>
        ))}
      </Slider>
      <DotGroup className={classes.dotGroup} />
    </CarouselProvider>
  )
}

const List = ({ items, children }) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
  const numberOfItems = isTablet ? 3 : 4

  return (
    <Grid container spacing={isTablet ? 1 : 2}>
      {items.slice(0, numberOfItems).map((item, i) => (
        <Grid key={i} item sm={isTablet ? 4 : 3}>
          {children({ item })}
        </Grid>
      ))}
    </Grid>
  )
}

const useStylesEvents = makeStyles(theme => ({
  emptyText: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  eventsButton: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  itemsContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
  },
  seeAllContainer: {
    textAlign: 'center',
  },
  link: {
    textDecoration: 'none',
  },
}))

const OfferingSection = ({
  allLink: { href, label },
  createDetailsLink,
  data,
  emptyText,
  isEvent,
  title,
}) => {
  const theme = useTheme()
  const classes = useStylesEvents()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const render = content => <Section title={title}>{content}</Section>

  if (!data || !data.length) {
    return render(
      <Typography color="textSecondary" className={classes.emptyText}>
        {emptyText}
      </Typography>
    )
  }

  const Component = isDesktop ? List : Carousel

  return render(
    <>
      <div className={classes.itemsContainer}>
        <Component items={data}>
          {({ item: { memberRegistered, ...item } }) => (
            <RouterLink
              className={classes.link}
              to={createDetailsLink(item.id)}
            >
              <OfferingCard
                className={classes.offeringCard}
                registered={memberRegistered}
                isEvent={isEvent}
                {...item}
              />
            </RouterLink>
          )}
        </Component>
      </div>

      {href && (
        <div className={classes.seeAllContainer}>
          <Link
            underline="none"
            className={classes.eventsButton}
            component={RouterLink}
            to={href}
          >
            <Button fullWidth={!isDesktop} variant="outlined">
              {label}
            </Button>
          </Link>
        </div>
      )}
    </>
  )
}

export default OfferingSection
