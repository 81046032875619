import React from 'react'
import { parsers } from 'novus-common/lib/modules/api'
import { useApiGetWithProfileData } from 'novus-common/lib/hooks'
import OfferingSection from './OfferingSection'

const limit = 10

const BenefitsSection = () => {
  const [data] = useApiGetWithProfileData({
    path: `/benefits/?tier=mine&limit=${limit}`,
    parse: parsers.parseBenefits,
  })

  if (!data) {
    return null
  }

  return (
    <OfferingSection
      allLink={{ href: '/benefits', label: 'See All Benefits' }}
      createDetailsLink={id => `/benefits/${id}`}
      data={data.items}
      title="Partner Benefits"
      emptyText="Exclusive range of partner benefits coming soon..."
    />
  )
}

export default BenefitsSection
