import React from 'react'
import { makeStyles } from '@material-ui/core'
import Section from '../layout/Section'
import MembershipCard from '../cards/MembershipCard'
import { useProfile } from 'novus-common/lib/hooks'

const useStyles = makeStyles(theme => ({
  membershipCard: {
    margin: '0 auto',
  },
}))

const MembershipSection = () => {
  const classes = useStyles()
  const [data] = useProfile()

  if (!data) {
    return null
  }

  return (
    <Section>
      <MembershipCard className={classes.membershipCard} {...data} />
    </Section>
  )
}

export default MembershipSection
