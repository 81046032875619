import React from 'react'
import { Container } from '@material-ui/core'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'
import { useApp } from 'novus-common/lib/hooks'
import BenefitsSection from '../sections/BenefitsSection'
import EventsSection from '../sections/EventsSection'
import MembershipSection from '../sections/MembershipSection'
import RegisteredEventsSection from '../sections/RegisteredEventsSection'

const useStyles = makeStyles(() => ({
  root: {
    ['&.loading']: {
      visibility: 'hidden',
    },
  },
}))

const Home = () => {
  const { loading } = useApp()
  const styles = useStyles()

  return (
    <Container className={classNames(styles.root, { loading })}>
      <MembershipSection />
      <EventsSection />
      <RegisteredEventsSection />
      <BenefitsSection />
    </Container>
  )
}

export default Home
