import React from 'react'
import { Container } from '@material-ui/core'
import { navigate } from '@reach/router'

import UserDetailsForm from '../form/UserDetailsForm'
import AccountHeading from '../typography/AccountHeading'
import { useApp, useApi, useProfile } from 'novus-common/lib/hooks'
import { parsers } from 'novus-common/lib/modules/api'

const MyProfile = () => {
  const [profile, setProfile] = useProfile()
  const [_, setRequest, loading, error] = useApi({
    disableAppLoader: true,
    onSuccess: response => {
      setProfile(parsers.parseProfile(response))
      displaySnackMessage('Profile updated!', 'success')
      navigate('/account')
    },
  })
  const { displaySnackMessage } = useApp()

  const handleSubmit = data => {
    const body = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone_number: data.phoneNumber,
    }

    setRequest({
      method: 'patch',
      path: '/profile/',
      init: { body },
    })
  }

  if (!profile) {
    return null
  }

  return (
    <Container maxWidth="xs">
      <AccountHeading>Update Profile</AccountHeading>

      <UserDetailsForm
        {...profile}
        error={error && error.message}
        loading={loading}
        onSubmit={handleSubmit}
      />
    </Container>
  )
}

export default MyProfile
