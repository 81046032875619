import React from 'react'
import { Container, Card, CardContent } from '@material-ui/core'
import { navigate } from '@reach/router'
import AccountHeading from '../typography/AccountHeading'
import ChangePaymentCard from '../ChangePaymentCard'

export default () => (
  <Container maxWidth="xs">
    <AccountHeading>Change Payment Card</AccountHeading>

    <Card>
      <CardContent>
        <ChangePaymentCard onSuccess={() => navigate('/account')} />
      </CardContent>
    </Card>
  </Container>
)
