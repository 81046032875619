import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  CalendarToday as CalendarTodayIcon,
  Done as DoneIcon,
  StarBorder as StarBorderIcon,
} from '@material-ui/icons'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { Heading } from '../typography'
import CapacityChip from './chips/CapacityChip'
import RegisteredChip from './chips/RegisteredChip'

const useStyles = makeStyles(theme => ({
  actionArea: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  calendarIcon: {
    fontSize: '1rem',
    marginRight: theme.spacing(1) / 2,
    position: 'relative',
    top: 2,
  },
  media: {
    flexGrow: 1,
    position: 'relative',
  },
  meta: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  registered: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  root: {
    height: 325,
  },
  starIcon: {
    fontSize: '1rem',
    marginRight: theme.spacing(1) / 2,
    position: 'relative',
    top: 3,
  },
  badTier: {
    opacity: 0.5,
  },
}))

export const OfferingCard = ({
  capacity,
  category = {},
  className,
  date,
  image,
  isEvent,
  partner,
  registered,
  registrationCount,
  title,
  tier,
  badTier,
}) => {
  const classes = useStyles()

  return (
    <Card
      className={classNames(classes.root, className, {
        [classes.badTier]: badTier,
      })}
    >
      <CardActionArea className={classes.actionArea}>
        <CardMedia className={classes.media} image={image}>
          {registered && <RegisteredChip />}
          {!!capacity && (
            <CapacityChip
              capacity={capacity}
              registrationCount={registrationCount}
            />
          )}
        </CardMedia>
        <CardContent>
          <Heading variant="h3">{title}</Heading>
          <Typography variant="body2" gutterBottom={tier || date}>
            {isEvent ? category.name : partner}
          </Typography>
          <div className={classes.meta}>
            {tier && (
              <Typography variant="body1">
                <StarBorderIcon className={classes.starIcon} />
                {tier}
              </Typography>
            )}
            {date && (
              <Typography variant="body1">
                <CalendarTodayIcon className={classes.calendarIcon} />
                {date}
              </Typography>
            )}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

OfferingCard.propTypes = {
  badTier: PropTypes.bool,
  capacity: PropTypes.number,
  category: PropTypes.shape({
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  date: PropTypes.string,
  image: PropTypes.string.isRequired,
  partner: PropTypes.string.isRequired,
  registered: PropTypes.bool,
  registrationCount: PropTypes.number,
  title: PropTypes.string.isRequired,
  tier: PropTypes.string,
}

export default OfferingCard
