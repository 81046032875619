import React, { useEffect } from 'react'
import { Location, Router, navigate } from '@reach/router'
import { withAuthenticator } from 'aws-amplify-react'
import DateFnsUtils from '@date-io/moment'
import { SnackbarProvider, useSnackbar } from 'notistack'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { AppProvider } from 'novus-common/lib/modules/app'
import { createApi } from 'novus-common/lib/modules/api'
import { ProfileProvider } from 'novus-common/lib/modules/profile'
import { useApp, useProfile } from 'novus-common/lib/hooks'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import {
  Benefit,
  Benefits,
  ChangePassword,
  Contact,
  ChangePaymentCard,
  Event,
  Events,
  Home,
  Logout,
  ManageBenefits,
  MyAccount,
  MyProfile,
  Privacy,
  SignUp,
  Terms,
} from '../pages'
import { ForgotPassword, RequireNewPassword, SignIn } from '../auth'
import theme from '../../css/theme'
import './App.css'

const useStyles = makeStyles(() => ({
  main: {
    flex: '1 1 100%',
    margin: '0 auto',
    minHeight: '100%',
    position: 'relative',
    paddingTop: theme.spacing(10),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(12),
    },
  },
  notifictionError: {
    color: '#fff',
  },
  notificationSuccess: {
    color: '#fff',
  },
}))

const Route = ({ children }) => children

const AppMessages = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { error, snackMessage, closeSnackbar } = useApp()

  useEffect(() => {
    if (!snackMessage) {
      closeSnackbar()
      return
    }

    const { message, type } = snackMessage

    enqueueSnackbar(message, { variant: type })
  }, [snackMessage])

  useEffect(() => {
    error
      ? enqueueSnackbar(error.message, { variant: 'error' })
      : closeSnackbar()
  }, [error])

  return null
}

const Redirect = ({ path, children }) => {
  const [profileData] = useProfile()
  const go = to => path !== to && navigate(to)

  useEffect(() => {
    switch (true) {
      case !profileData || path.startsWith('/sign-up'):
        return
      case profileData.selectBenefits:
        go('/sign-up/select-benefits')
        return
    }
  }, [profileData, path])

  return children
}

const Layout = () => {
  const classes = useStyles()

  return (
    <ProfileProvider>
      <Location>
        {({ location }) => (
          <>
            <Header path={location.pathname} />
            <main className={classes.main}>
              <Redirect path={location.pathname}>
                <Router>
                  <Home path="/" />
                  <Route path="events">
                    <Events path="/" />
                    <Event path=":id/*" />
                  </Route>
                  <Route path="benefits">
                    <Benefits path="/" />
                    <Benefit path=":id" />
                  </Route>
                  <Route path="account">
                    <MyAccount path="/" />
                    <MyProfile path="profile" />
                    <ChangePaymentCard path="change-payment-card" />
                    <ChangePassword path="change-password" />
                    <ManageBenefits path="manage-benefits" />
                    <Logout path="logout" />
                  </Route>
                  <Route path="sign-up">
                    <SignUp.ChangePaymentCard path="change-payment-card" />
                    <SignUp.SelectBenefits path="select-benefits" />
                  </Route>
                </Router>
              </Redirect>
            </main>
          </>
        )}
      </Location>
      <Footer className={classes.footer} />
    </ProfileProvider>
  )
}

const LayoutWithAuthenticator = withAuthenticator(
  Layout,
  false,
  [<SignIn />, <ForgotPassword />, <RequireNewPassword />],
  null,
  { toast: { display: 'none' } }
)

const api = createApi()

const App = () => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider
          maxSnack={3}
          classes={{
            variantError: classes.notifictionError,
            variantSuccess: classes.notificationSuccess,
          }}
        >
          <AppProvider api={api}>
            <CssBaseline />
            <Router className="router">
              <Contact path="/contact" />
              <Privacy path="/privacy" />
              <Terms path="/terms" />
              <SignUp.SignUp path="/sign-up" />
              <LayoutWithAuthenticator path="/*" />
            </Router>
            <AppMessages />
          </AppProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default App
