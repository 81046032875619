import { Container, makeStyles } from '@material-ui/core'
import React from 'react'

import SecondaryNav from '../layout/SecondaryNav'
import Heading from '../typography/Heading'
import AccountSummary from '../AccountSummary'

const links = [
  { to: 'profile', label: 'Update profile' },
  { to: 'change-password', label: 'Change password' },
  { to: 'change-payment-card', label: 'Change payment card' },
  { to: 'manage-benefits', label: 'Manage benefits' },
  { to: 'logout', label: 'Logout' },
]

const useStyles = makeStyles(theme => ({
  summary: {
    marginBottom: theme.spacing(2),
  },
}))

const MyAccount = () => {
  const classes = useStyles()

  return (
    <Container maxWidth="xs">
      <Heading bigGutter={true} underlined variant="h2">
        Account
      </Heading>
      <AccountSummary className={classes.summary} />
      <SecondaryNav links={links} />
    </Container>
  )
}

export default MyAccount
