import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
  loading: {
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    position: 'absolute',
    top: '50%',
  },
  wrap: {
    position: 'relative',
  },
}))

const _Button = ({ children, disabled, loading, ...props }) => {
  const classes = useStyles()
  return (
    <span className={classes.wrap}>
      <Button disabled={disabled || loading} {...props}>
        {children}
      </Button>
      {loading && <CircularProgress className={classes.loading} size={24} />}
    </span>
  )
}

export default _Button
