import {
  AppBar,
  Button,
  Container,
  IconButton,
  LinearProgress,
  Link,
  Toolbar,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { AccountCircle as AccountCircleIcon } from '@material-ui/icons'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from '@reach/router'
import { useApp } from 'novus-common/lib/hooks'

import MobileMenu from './MobileMenu'
import logo from './logo.svg'

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  linkButtonActive: {
    color: theme.palette.primary.main,
    textDecoration: 'none !important',
  },
  linkLabelActive: {
    position: 'relative',
    '&:after': {
      backgroundColor: theme.palette.primary.main,
      bottom: 0,
      content: '""',
      left: 0,
      position: 'absolute',
      right: 0,
      height: 1,
    },
  },
  links: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  logo: {
    height: 45,
    left: '50%',
    position: 'absolute',
    top: 'calc(50% - 2px)',
    transform: 'translate(-50%, -50%)',
    width: 45,
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      height: 50,
      width: 50,
    },
  },
  root: {
    backgroundColor: '#2B2B2B',
  },
  toolbar: {
    padding: theme.spacing(0, 1),
  },
}))

const links = [
  { label: 'Novus Events', href: '/events' },
  { label: 'Partner Benefits', href: '/benefits' },
]

export const Header = ({ path }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const { loading } = useApp()
  const progressProps = loading ? {} : { variant: 'determinate', value: 100 }

  return (
    <AppBar className={classes.root}>
      <Container>
        <Link component={RouterLink} to="/">
          <img src={logo} className={classes.logo} />
        </Link>
        <Toolbar className={classes.toolbar} disableGutters={true}>
          <div className={classes.links}>
            {links.map(({ label, href }, i) => (
              <Link
                key={i}
                className={classes.link}
                component={RouterLink}
                to={href}
              >
                <Button
                  classes={{
                    label: href == path ? classes.linkLabelActive : null,
                  }}
                  className={classNames(classes.linkButton, {
                    [classes.linkButtonActive]: href == path,
                  })}
                >
                  {label}
                </Button>
              </Link>
            ))}
          </div>
          <span className={classes.grow} />
          {isDesktop ? (
            <Link component={RouterLink} to="/account">
              <IconButton>
                <AccountCircleIcon />
              </IconButton>
            </Link>
          ) : (
            <MobileMenu />
          )}
        </Toolbar>
      </Container>
      <LinearProgress color="primary" {...progressProps} />
    </AppBar>
  )
}

Header.propTypes = {
  loading: PropTypes.bool,
  path: PropTypes.string,
}

export default Header
