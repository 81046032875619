import { Chip, makeStyles } from '@material-ui/core'
import { Done as DoneIcon } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles(theme => ({
  registered: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}))

const RegisteredChip = () => {
  const classes = useStyles()
  return (
    <Chip
      className={classes.registered}
      color="primary"
      icon={<DoneIcon />}
      label="Registered"
    />
  )
}

export default RegisteredChip
