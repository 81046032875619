import { IconButton, makeStyles } from '@material-ui/core'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import { useNavigate } from '@reach/router'
import React from 'react'

import Heading from './Heading'

const useStyles = makeStyles(theme => ({
  back: {
    left: 0,
    position: 'absolute',
    top: theme.spacing(-1),
    zIndex: 1,
  },
  heading: {
    position: 'relative',
  },
}))

const AccountHeading = ({ children }) => {
  const classes = useStyles()
  const nav = useNavigate()
  const onClickBack = () => nav('/account')

  return (
    <Heading
      bigGutter={true}
      className={classes.heading}
      underlined
      variant="h2"
    >
      <IconButton
        className={classes.back}
        onClick={onClickBack}
        variant="contained"
      >
        <ArrowBackIcon />
      </IconButton>
      {children}
    </Heading>
  )
}

export default AccountHeading
