import {
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1) / 2,
    zIndex: 1,
  },
  content: {
    paddingTop: theme.spacing(4),
  },
}))

export const DialogWithClose = ({
  children,
  className,
  open,
  onClose,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      onClose={onClose}
      {...props}
    >
      <IconButton className={classes.closeIcon} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent className={classNames(classes.content, className)}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

DialogWithClose.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default DialogWithClose
