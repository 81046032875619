import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useApp, useApi, useProfile } from 'novus-common/lib/hooks'
import { parsers } from 'novus-common/lib/modules/api'
import { createPaymentMethod } from 'novus-common/lib/modules/stripe'
import PaymentForm from './form/PaymentForm'

const useStyles = makeStyles(theme => ({
  root: {},
}))

const ChangePaymentCard = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false)
  const [profile, setProfile] = useProfile()
  const [_, setRequest, apiLoading] = useApi({
    disableAppLoader: true,
    onSuccess: response => {
      setProfile(parsers.parseProfile(response))
      displaySnackMessage('Payment card updated!', 'success')
      onSuccess && onSuccess()
    },
  })
  const { displaySnackMessage } = useApp()
  const classes = useStyles()

  const handleSubmit = async ({ cvc, expiry, name, number }) => {
    setLoading(true)

    try {
      const [expMonth, expYear] = expiry.split('/').map(n => +n)
      const result = await createPaymentMethod(
        {
          type: 'card',
          card: {
            number: number.replace(' ', ''),
            cvc,
            exp_month: expMonth,
            exp_year: expYear,
          },
          billing_details: {
            email: profile.email,
            name,
            phone: profile.phoneNumber,
          },
        },
        profile.stripePublicKey
      )

      const body = {
        payment_method_id: result.data.id,
      }

      setRequest({
        method: 'patch',
        path: '/profile/',
        init: { body },
      })
    } catch (error) {
      console.log(error)
      if (error.message) {
        throw { form: error.message }
      }
      throw error
    } finally {
      setLoading(false)
    }
  }

  if (!profile) {
    return null
  }

  const values =
    profile.paymentMethod && profile.paymentMethod.billingDetails
      ? {
          name: profile.paymentMethod.billingDetails.name,
        }
      : {
          name: profile.firstName + ' ' + profile.lastName,
        }

  return (
    <PaymentForm
      {...values}
      loading={loading || apiLoading}
      onSubmit={handleSubmit}
    />
  )
}

export default ChangePaymentCard
