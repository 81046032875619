import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import DialogWithClose from '../../dialogs/DialogWithClose'
import ChangePaymentCard from '../../ChangePaymentCard'
import Heading from '../../typography/Heading'

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingBottom: theme.spacing(3),
  },
}))

export default ({ data, onSuccess, ...props }) => {
  const classes = useStyles()
  return (
    <DialogWithClose {...props} className={classes.root} maxWidth="xs">
      <Heading variant="h3" className={classes.heading}>
        Add Card To Register
      </Heading>
      <Typography className={classes.message} variant="body2">
        To register for an event you need to have a payment card on file to pay
        for additional guests and no-show fees.
      </Typography>
      <ChangePaymentCard onSuccess={onSuccess} />
    </DialogWithClose>
  )
}
