import React from 'react'
import { Container } from '@material-ui/core'
import AccountHeading from '../typography/AccountHeading'
import SelectPlans from '../SelectPlans'

export default () => (
  <Container maxWidth="xs">
    <AccountHeading>Manage Benefits</AccountHeading>
    <SelectPlans />
  </Container>
)
