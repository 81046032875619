import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import PropTypes from 'prop-types'
import React from 'react'
import * as Yup from 'yup'

import Button from './Button'

const plural = (num, text) => (num == 1 ? `1 ${text}` : `${num} ${text}s`)

export const formatPrice = cost =>
  cost &&
  Number(cost).toLocaleString('en-US', {
    style: 'currency',
    currency: 'AED',
    currencyDisplay: 'code',
  })

const makeSubmitText = (
  guests,
  memberGuestQuota,
  memberPersonalQuota,
  guestsPaidCost,
  personalPaidCost
) => {
  let txt = 'Register For Event'
  let cost = 0
  if (guests > 0) {
    let paid = guests - memberGuestQuota
    if (paid > 0) {
      cost += paid * guestsPaidCost
    }
  }
  if (memberPersonalQuota == 0) {
    cost += personalPaidCost
  }
  if (cost > 0) {
    txt = `${txt} (${formatPrice(cost)})`
  }
  return txt
}

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  guestsInput: {
    marginBottom: theme.spacing(2),
  },
  personalHelper: {
    marginBottom: theme.spacing(2),
  },
  terms: {
    marginBottom: theme.spacing(2),
  },
}))

const EventRegistrationForm = ({
  cancellationPeriod,
  capacity,
  guestsPaidCost,
  memberGuestQuota,
  memberPersonalQuota,
  personalPaidCost,
  loading,
  noShowFee,
  onSubmit,
  registrationCount,
}) => {
  const classes = useStyles()
  const guestsText = ''
  const spacesRemaining = !!capacity ? capacity - registrationCount : undefined

  const Schema =
    spacesRemaining != undefined
      ? Yup.object().shape({
          guests: Yup.number()
            .max(
              spacesRemaining - 1,
              `Max ${plural(spacesRemaining - 1, 'guest')}. Only ${plural(
                spacesRemaining,
                'space'
              )} left`
            )
            .required('Required'),
        })
      : undefined

  // Personal quota
  let personalHelper
  if (memberPersonalQuota > 0) {
    personalHelper = `You have ${plural(
      memberPersonalQuota,
      'free event'
    )} left in your quota.`
  } else {
    personalHelper = `You have no free events left. Attendance will cost ${formatPrice(
      personalPaidCost
    )}.`
  }

  // Guest quota helper
  let helperText
  if (memberGuestQuota > 0) {
    helperText = `You have ${plural(
      memberGuestQuota,
      'free additional guest'
    )} left in your quota.`
  } else {
    helperText = 'You have no free guests remaining.'
  }
  helperText = `${helperText} Further guests cost ${formatPrice(
    guestsPaidCost
  )}.`
  return (
    <Formik
      initialValues={{ guests: '0' }}
      onSubmit={onSubmit}
      validationSchema={Schema}
    >
      {({ handleSubmit, isValid, values }) => (
        <div className={classes.form}>
          <Typography className={classes.personalHelper}>
            {personalHelper}
          </Typography>
          <TextField
            className={classes.guestsInput}
            disabled={spacesRemaining != undefined && spacesRemaining == 0}
            inputProps={{
              max: spacesRemaining,
              min: 0,
            }}
            helperText={helperText}
            label="Additional Guests"
            name="guests"
            type="number"
            variant="filled"
          />
          <Typography variant="subtitle" gutterBottom className={classes.terms}>
            {noShowFee
              ? `Please note there is a no show fee of ${formatPrice(
                  noShowFee
                )}. `
              : null}
            {cancellationPeriod
              ? `You can cancel your registration up to ${cancellationPeriod} days before the event.`
              : `You can cancel your registration at any time.`}
          </Typography>
          <Button
            color="primary"
            disabled={loading || !isValid}
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
          >
            {makeSubmitText(
              values.guests,
              memberGuestQuota,
              memberPersonalQuota,
              guestsPaidCost,
              personalPaidCost
            )}
          </Button>
        </div>
      )}
    </Formik>
  )
}

export default EventRegistrationForm
