import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'

import Button from '../form/Button'
import PasswordFieldFormik from './PasswordFieldFormik'

const Schema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8)
    .required('Required'),
  newPassword: Yup.string()
    .min(8)
    .required('Required'),
})

const View = ({ disabled, isSubmitting, onSubmit }) => (
  <>
    <Grid item xs={12}>
      <Field
        component={PasswordFieldFormik}
        fullWidth
        label="Old Password"
        name="oldPassword"
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        component={PasswordFieldFormik}
        fullWidth
        label="New Password"
        name="newPassword"
      />
    </Grid>
    <Grid item xs={12}>
      <Button
        color="primary"
        disabled={disabled}
        fullWidth
        loading={isSubmitting}
        onClick={onSubmit}
        type="submit"
        variant="contained"
      >
        Submit
      </Button>
    </Grid>
  </>
)

export const ChangePasswordForm = ({
  oldPassword = '',
  newPassword = '',
  error,
  onSubmit,
}) => {
  const [formError, setFormError] = useState(error)
  const initialValues = { oldPassword, newPassword }

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setSubmitting }
  ) => {
    try {
      setFormError(error)
      await onSubmit(values)
      resetForm(initialValues)
    } catch (err) {
      if (err.form) setFormError(err.form)
      if (err.fields) setErrors(err.fields)
      if (!err.form && !err.fields) {
        setFormError('Error processing')
      }
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    error && setFormError(error.form)
  }, [error])

  return (
    <Card>
      <CardContent>
        {formError && (
          <Typography color="error" gutterBottom>
            {formError}
          </Typography>
        )}

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={Schema}
        >
          {({ handleSubmit, isSubmitting, isValid }) => (
            <Form>
              <Grid container spacing={2}>
                <View
                  onSubmit={handleSubmit}
                  disabled={!isValid || isSubmitting}
                  isSubmitting={isSubmitting}
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}

export default ChangePasswordForm
