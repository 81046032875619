import React from 'react'
import { Container, makeStyles } from '@material-ui/core'

import Heading from '../typography/Heading'

const useStyles = makeStyles(theme => ({
  heading: {
    paddingTop: theme.spacing(3),
  },
}))

const Privacy = () => {
  const classes = useStyles()
  return (
    <Container maxWidth="md">
      <Heading
        bigGutter={true}
        underlined
        variant="h2"
        className={classes.heading}
      >
        Privacy Notice
      </Heading>
      <div>
        <h3>1. Introduction</h3>
        <p>
          Welcome to Novus’s Privacy Notice. Berkeley Assets Limited (
          <strong>we</strong> or <strong>us</strong>) are committed to
          protecting the personal information and privacy of the individuals who
          use our website and mobile app (the <strong>service</strong>). This
          Privacy Notice will inform you as to how we look after your personal
          data when you visit our website or the Novus app (regardless of where
          you visit it from) and tell you about your privacy rights and how the
          law protects you. You will be uploading and sharing your personal data
          as part of being a member of and using the Novus service. Please take
          care to only share data that you are happy to be publicly viewable –
          Novus has no control over any data once it has been made public by
          you. We want to ensure you get the most out of the service, whilst
          safeguarding your privacy.
        </p>
        <p>
          This Privacy Notice, together with any other documents referred to in
          it, sets out the basis on which any personal data that we collect from
          or about you, or that you provide to us, will be processed by us.
          Please read the following carefully to understand our views and
          practices regarding your personal data and how we treat it.
        </p>
        <p>
          For the purpose of the General Data Protection Regulation, the data
          controller is Berkeley Assets Limited, whose registered office is at
          23 Berkeley Square, London W1J 6HE, United Kingdom.
        </p>
        <p>
          We may update this Privacy Notice from time to time. Any changes we
          make in the future will be posted on the app and on our website at
          www.novus-ba.com. Please check back regularly to see any updates or
          changes.
        </p>
        <h3>2. Data Protection Officer</h3>
        <p>
          The contact details for our Data Protection Officer (
          <strong>DPO</strong>) are as follows:
        </p>
        <p>
          Name: Selina Johnston
          <br />
          Job title: Director
          <br />
          Email: sj@berkeley-assets.com
        </p>
        <p>
          It is the responsibility of the DPO to keep our organisation and our
          staff informed and advised about their obligations to comply with data
          protection laws, to monitor compliance with those laws, to advise on
          data protection impact assessments, to train staff and conduct
          internal audits, and to be the first point of contact for supervisory
          authorities and for individuals whose personal data we are processing.
        </p>
        <h3>3. Types of personal data that Novus collects about you</h3>
        <p>
          Personal data, or personal information, means any information about an
          individual from which that person can be identified. It does not
          include data where the identity has been removed (anonymous data). We
          process personal data about our stakeholders (by which we mean
          individual users who subscribe to our website or app, and any other
          person who has contact with us) in a range of ways, and for a range of
          purposes.
        </p>
        <p>
          In respect of individual users of the Novus service, we may collect,
          receive, use, store and transfer different kinds of personal data
          about you which we have grouped together follows: (a){' '}
          <strong>Identity Data</strong> which includes your first name,
          username or similar identifier, title, age and gender, photographs
          that you choose to upload; (b) <strong>Contact Data</strong> which
          includes your email address; (c) <strong>Technical Data</strong> which
          includes your internet protocol (IP) address, your login data, browser
          type and version, time zone setting and location, browser plug-in
          types and versions, operating system and platform and other technology
          on the devices you use to access this website or our app; (d){' '}
          <strong>Profile Data</strong> which includes your username and
          password, and any other information or data you upload to your profile
          (e) <strong>Usage Data </strong>which includes information about how
          you use our website and app, products and services; (f){' '}
          <strong>Marketing and Communications Data </strong> which includes
          your preferences in receiving marketing from us and our third parties
          and your communication preferences.
        </p>
        <p>
          The nature of the Novus service means that we will also collect and
          process what is known as <strong>Special Category Data</strong>. This
          is a category of personal data that is recognised in data protection
          law as being of a particular sensitive nature, and may include details
          about your sex life, sexual orientation, political opinions, race or
          ethnicity, religious or philosophical beliefs, trade union membership,
          information about your health, and genetic and biometric data.{' '}
          <strong>
            Because Special Category Data is sensitive, we need your SPECIFIC
            consent to process it, and this is why we ask you to let us know
            that you agree to the terms of our Privacy Notice
          </strong>
          . We wouldn’t be able to provide the Novus service to you without
          processing of your Special Category Data, so by indicating that you
          are happy for us to carry out such processing during the sign-up
          process, we can continue to provide the Novus service to you.
        </p>
        <p>
          We also collect, use and share what we call{' '}
          <strong>Aggregated Data</strong>, which includes statistical or
          demographic data, for any purpose. Aggregated Data may be derived from
          your personal data but is not considered personal data in law, as this
          data does not directly or indirectly reveal your identity. For
          example, we may aggregate your Usage Data to calculate the percentage
          of users accessing a specific website or app feature. However, if we
          combine or connect Aggregated Data with your personal data so that it
          can be used to directly or indirectly identify you, we treat the
          combined data as personal data which will be used in accordance with
          this Privacy Notice.
        </p>
        <p>
          Novus uses third party payment providers chosen by the user. As such,
          Novus does not collect, store or have access to any financial data,
          payment will be taken directly through the app store and Google play.
        </p>
        <h3>4. How Novus collects your personal data</h3>
        <p>
          We use different methods to collect data from and about you, and may
          obtain data from a range of different sources. These are summarised
          below.
        </p>
        <p>
          <strong>Personal data that we receive directly from you</strong>. You
          may give us your Identity Data and Contact Data by filling in forms or
          by corresponding with us by email. This includes personal data you
          provide when you (a) sign up to the Novus service and create an
          account, (b) subscribe to Novus, (c) request marketing to be sent to
          you or (d) provide us with some feedback (e) when you verify with
          facebook.
        </p>
        <p>
          <strong>
            Personal data that we receive by technical means based on your use
            of the service.
          </strong>{' '}
          As you interact with our website and/or app, we may automatically
          collect Technical Data about your equipment, browsing actions and
          patterns. We collect this personal data by using cookies, and other
          similar technologies. Please see the section on cookies below for
          further details.
        </p>
        <p>
          <strong>
            Personal data that we receive about you from other Novus users.
          </strong>{' '}
          Other users of the service may provide information about you in the
          course of using the service: for example if they contact us about you
          or through the report button within the app.
        </p>
        <p>
          <strong>
            Personal data that we receive about you from other third parties or
            publicly available sources.
          </strong>{' '}
          We may receive personal data about you from various third parties or
          publicly available sources. These include (a){' '}
          <strong>Technical Data</strong> that we receive from (i) analytics
          providers such as Google (based inside and outside the EU); (ii)
          advertising networks such as Facebook and Instagram (based inside and
          outside the EU); and (iii) search information providers such as Google
          (based inside and outside the EU); (b) <strong>Identity Data</strong>{' '}
          and <strong>Contact Data</strong> from social media platforms to
          enable you to login to your Novus account (for example, Facebook); and
          (d) where we publish advertisements on a partner’s websites and
          platforms, these partners may communicate personal data about you to
          us, relating to the success of the campaign.
        </p>
        <h3>5. Lawful basis of processing</h3>
        <p>
          In order to comply with data protection law, it is necessary for the
          person or organisation using the personal data to show what is known
          as a “lawful basis of processing”. In the case of Novus, we will
          generally only use your personal data on the following basis: (a)
          where we need to perform the contract we have entered into with you to
          provide the Novus service; and/or (b) where it is necessary for our
          legitimate interests (or those of a third party) and your interests
          and fundamental rights do not override those interests; and/or (c)
          where we need to do in order to comply with a legal or regulatory
          obligation; and/or we have your consent to the relevant processing.
        </p>
        <p>
          We do not generally rely on consent as a legal basis for processing
          your personal data other than in relation to sending third-party
          direct marketing communications to you via email or text message. We
          may, however, rely on consent as a condition for processing Special
          Category Data.
        </p>
        <p>
          Note that we may process your personal data for more than one “lawful
          basis” depending on the specific purpose for which we are using your
          data. Please contact us if you need details about the specific legal
          bases we are relying on to process your personal data where more than
          one ground has been set out in the table below.
        </p>
        <p>
          The specific purposes for which we use your personal data are set out
          in Section 6 below. We will only use your personal data for the
          specified purposes for which it was originally collected unless we
          reasonably consider that we need to use it for another reason and that
          reason is compatible with the original purpose.
        </p>
        <p>
          If you would like us to explain how the processing for the new purpose
          is compatible with the original purpose, please contact us. If we need
          to use your personal data for an unrelated purpose, we will notify you
          and we will explain the legal basis which allows us to do so. Please
          note that we may process your personal data without your knowledge or
          consent, in compliance with the principles set out in this Privacy
          Notice, where this is required or permitted by law.
        </p>
        <h3>6. How Novus uses your personal data</h3>
        <p>
          This section sets out how Novus uses your personal data, along with
          the lawful basis of processing in each case.
        </p>
        <p>
          <strong>Initial registration and set-up of service.</strong> In order
          to register you as a new user of the Novus service, we need to access
          and process Identity Data, Contact Data, Profile Data and Special
          Category Data). The relevant lawful bases are (a) the performance of
          our contract with you and (b) (in the case of Special Category Data
          specifically) your consent.
        </p>
        <p>
          <strong>Membership processing.</strong> In order to process your
          membership application (which includes analysing your profile,
          matching you with other users, providing a platform for you to
          communicate with other users, and allowing other users to view your
          profile), we need to access and use Identity Data, Contact Data,
          Profile Data, Marketing and Communications Data, and Special Category
          Data. The relevant lawful bases are (a) the performance of our
          contract with you, (b) the processing is necessary for our legitimate
          interests (in order to provide a personalised service) and (c) (in the
          case of Special Category Data specifically) your consent.
        </p>
        <p>
          <strong>Management of our relationship with you.</strong> In order to
          notify you about changes to our Terms and Conditions or to this
          Privacy Notice, we need to access and use Identity Data, Contact Data,
          Profile Data, Usage Data, and Marketing and Communications Data. The
          relevant lawful bases are (a) the performance of our contract with
          you, (b) the processing is necessary to comply with a legal
          obligation, (c) the processing is necessary for our legitimate
          interests (to keep our records updated and to study how customers use
          our products/services, and to develop and grow our business) and (d)
          (in the case of Special Category Data specifically) your consent.
        </p>
        <p>
          <strong>
            Administration and protection of our business and the Novus app and
            website.
          </strong>{' '}
          In order to undertake troubleshooting, data analysis, testing, system
          maintenance, support, reporting and hosting of data, we need to access
          and use Identity Data, Contact Data, Technical Data and Profile Data.
          The relevant lawful bases are (a) the processing is necessary for our
          legitimate interests (for running our business, provision of
          administration and IT services, network security, to prevent fraud and
          in the context of a business reorganisation or group restructuring
          exercise) and (b) the processing is necessary to comply with a legal
          obligation.
        </p>
        <p>
          <strong>Advertising and content delivery.</strong> In order to deliver
          relevant content and advertisements to you, and to measure or
          understand the effectiveness of the advertising we serve to you, we
          need to access and use Identity Data, Contact Data, Profile Data,
          Usage Data, Marketing and Communications Data. The relevant lawful
          basis is that the processing is necessary for our legitimate interests
          (to study how customers use our products/services, to develop them, to
          grow our business and to inform our marketing strategy).
        </p>
        <p>
          <strong>Data analytics and service improvement.</strong> In order to
          improve the Novus website and app, any of our other products and
          services, and the customer experience generally, we need to access and
          use Technical Data and Usage Data. The relevant lawful basis is that
          the processing is necessary for our legitimate interests (to define
          types of customers for our products and services, to keep our website
          and app updated and relevant, to develop our business and to inform
          our marketing strategy).
        </p>
        <p>
          <strong>Marketing.</strong> In order to make suggestions and
          recommendations to you about Novus goods or services that may be of
          interest to you, we need to access and use Identity Data, Contact
          Data, Usage Data, Profile Data, and Special Category. We may also
          share your personal data with other companies for marketing purposes.
          The relevant lawful basis in each case is your consent.{' '}
          <strong>
            If at any time you wish to stop receiving marketing messages from us
            or from third parties who have obtained your details through us, you
            can ask us or third parties to stop sending you such messages by
            following the opt-out links on any marketing message sent to you or
            by contacting us at any time or within the settings page of the app.
          </strong>
        </p>
        <h3>7. Cookies</h3>
        <p>
          The Novus website and app use cookies to distinguish you from other
          users of our website or app. You can set your browser to refuse all or
          some browser cookies or to alert you when websites set or access
          cookies. If you disable or refuse cookies, please note that some parts
          of this website or our app may become inaccessible or not function
          properly.
        </p>
        <h3>8. Your legal rights as a data subject</h3>
        <p>
          This section sets out the rights that you have as a data subject, by
          reason of the General Data Protection Regulation and the UK Data
          Protection Act 2018.
        </p>
        <p>
          You have the right to request access to the personal data that we hold
          about you (also known as a “data subject access request”). This
          enables you to receive a copy of the personal information we hold
          about you and to check that we are lawfully processing it.
        </p>
        <p>
          You have the right to request rectification of the personal data that
          we hold about you. This enables you to have any incomplete or
          inaccurate information we hold about you corrected.
        </p>
        <p>
          You have the right to request the erasure of the personal data that we
          hold about you (also known as “the right to be forgotten”). This
          enables you to ask us to delete or remove personal information where
          there is no good reason for us continuing to process it. You also have
          the right to ask us to delete or remove your personal information
          where you have exercised your right to object to processing (see
          below).
        </p>
        <p>
          You have the right to request the restriction of processing about you.
          This enables you to ask us to suspend the processing of personal
          information about you, for example, if you want us to establish its
          accuracy or the reason for processing it.
        </p>
        <p>
          You have the right to object to processing. Where we are processing
          your personal data solely on the grounds that there is a legitimate
          interest to do so, and there is something about your particular
          situation which makes you want to object to processing on this ground,
          then this enables you to challenge the processing. You also have the
          right to object where we are processing your personal information for
          direct marketing purposes.
        </p>
        <p>
          You have the right to data portability. This enables you to ask us to
          transfer your personal information to another party in certain
          circumstances.
        </p>
        <p>
          Where our processing of personal data is based on your having given
          consent, you also have the right as a data subject to withdraw that
          consent at any time.
        </p>
        <p>
          If you wish to invoke any of the above rights, please notify our Data
          Protection Officer using the contact details set out in Section 2
          above. Please include the words DATA PRIVACY REQUEST in the subject
          line of your email, or at the top of your letter.
        </p>
        <p>
          You also have the right to lodge a complaint with a supervisory
          authority. In the United Kingdom, the supervisory authority is the
          Office of the Information Commissioner, full contact details for which
          can be found at https://ico.org.uk/global/contact-us/
        </p>
        <h3>9. Recipients of Personal Data</h3>
        <p>
          <strong>Disclosure in the context of corporate transactions.</strong>{' '}
          We may share your personal information with third parties (a) if we
          sell or buy any business or assets, in which case we may disclose your
          personal information to the prospective seller or buyer of such
          business or assets, or (b) if Novus Ltd or substantially all of its
          assets are acquired by a third party, in which case personal
          information held by it about its customers will be one of the
          transferred assets.
        </p>
        <p>
          <strong>Disclosure required by law or for legal enforcement.</strong>{' '}
          We may share your personal information with third parties where (a) we
          are under a duty to do so in order to comply with any legal
          obligation, or (b) we require to do so in order to enforce or apply
          our own terms of use or terms and conditions of supply, or to protect
          the rights, property, or safety of Novus Ltd, its users, or others.
          This includes exchanging information with other companies and
          organisations for the purposes of fraud protection and credit risk
          reduction.
        </p>
        <h3>10. Data Retention</h3>
        <p>
          We will only retain your personal data for as long as necessary to
          fulfill the purposes we collected it for, including for the purposes
          of satisfying any legal, accounting, or reporting requirements.
        </p>
        <p>
          The appropriate retention period for any given type of personal data
          depends on a range of factors, including the nature and sensitivity of
          the personal data, the potential risk of harm from unauthorised use or
          disclosure, the purposes for which it was collected, and the
          applicable legal requirements.
        </p>
        <h3>11. International Transfers</h3>
        <p>
          Some of our external third parties are based outside the European
          Economic Area (EEA) so their processing of your personal data will
          involve a transfer of data outside the EEA. Whenever we transfer your
          personal data out of the EEA, we ensure a similar degree of protection
          is afforded to it by ensuring at least one of the following safeguards
          is implemented: (a) we will only transfer your personal data to
          countries that have been deemed to provide an adequate level of
          protection for personal data by the European Commission; (b) where we
          use certain service providers, we may use specific contracts approved
          by the European Commission which give personal data the same
          protection it has in Europe; (c) where we use providers based in the
          US, we may transfer data to them if they are part of the Privacy
          Shield which requires them to provide similar protection to personal
          data shared between Europe and the US. Please contact us if you want
          further information on the specific mechanism used by us when
          transferring your personal data out of the EEA.
        </p>
        <h3>12. Data Security</h3>
        <p>
          We have put in place appropriate security measures by hosting our app
          on AWS DynamoDB to prevent your personal data from being accidentally
          lost, used or accessed in an unauthorised way, altered or disclosed.
          In addition, we limit access to your personal data to those employees,
          agents, contractors and other third parties who have a business need
          to know. They will only process your personal data on our instructions
          and they are subject to a duty of confidentiality. We have put in
          place procedures to deal with any suspected personal data breach and
          will notify you and any applicable regulator of a breach where we are
          legally required to do so.
        </p>
        <h3>13. Contact details</h3>
        <p>
          You can contact us by email if you have any questions or concerns
          about personal data and privacy matters, using the contact details for
          the Data Protection Officer set out in Section 2. Please include the
          words DATA PRIVACY REQUEST in the subject line of your email, or at
          the top of your letter.
        </p>
      </div>
    </Container>
  )
}

export default Privacy
