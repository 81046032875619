import React from 'react'
import { navigate } from '@reach/router'
import { Container, Card, CardContent } from '@material-ui/core'
import Heading from '../../typography/Heading'
import ChangePaymentCard from '../../ChangePaymentCard'

export default () => {
  const handleSuccess = () => {
    navigate('/sign-up/select-benefits')
  }

  return (
    <Container maxWidth="xs">
      <Heading bigGutter underlined variant="h2">
        Setup Payment Card
      </Heading>

      <Card>
        <CardContent>
          <ChangePaymentCard onSuccess={handleSuccess} />
        </CardContent>
      </Card>
    </Container>
  )
}
