const {
  REACT_APP_STAGE,
  REACT_APP_NOVUS_USER_POOL_ID,
  REACT_APP_NOVUS_APP_CLIENT_ID,
  REACT_APP_NOVUS_API_URL,
} = process.env

const region = 'eu-west-2'
const env = REACT_APP_STAGE === 'prod' ? 'prod' : 'dev'
const dev = {
  cognito: {
    region,
    userPoolId: REACT_APP_NOVUS_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_NOVUS_APP_CLIENT_ID,
  },
  api: {
    url: REACT_APP_NOVUS_API_URL,
  },
}
const prod = dev

export default REACT_APP_STAGE === 'prod' ? prod : dev
