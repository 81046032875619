import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
// import classNames from 'classnames'

import { Heading } from '../typography'

// const useStyles = makeStyles(theme => ({ // TODO implement selected color
//   selected: {
//   },
// }))

export const OfferingCard = ({
  className,
  partner,
  title,
  selected,
  disabled,
  onClick,
}) => {
  return (
    <Card className={className} onClick={!disabled && onClick}>
      <CardActionArea>
        <CardContent>
          <Heading variant="h3">{selected ? title + ' *' : title}</Heading>
          <Typography variant="body2">
            {partner + (disabled ? ' (disabled)' : '')}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

OfferingCard.propTypes = {
  className: PropTypes.string,
  partner: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default OfferingCard
