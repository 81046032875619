import { Grid, Typography, makeStyles } from '@material-ui/core'
import { Form, Formik } from 'formik'
import React, { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import valid from 'card-validator'
import { TextField } from 'formik-material-ui'
import Button from '../Button'
import CreditCard from './card'

const PaymentSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  number: Yup.string()
    .test(
      'test-number',
      'Credit card number is invalid',
      value => valid.number(value).isValid
    )
    .required('Required'),
  expiry: Yup.string()
    .test(
      'test-number',
      'Expiration date is invalid',
      value => valid.expirationDate(value).isValid
    )
    .required('Required'),
  cvc: Yup.string()
    .label('CVC')
    .min(3)
    .max(4)
    .required('Required'),
})

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
  },
  linkArea: {
    marginTop: theme.spacing(1.5),
  },
  creditCard: {
    marginBottom: theme.spacing(2),
  },
  submit: {},
}))

export const PaymentForm = ({
  name = '',
  number = '',
  expiry = '',
  cvc = '',
  loading = false,
  error,
  onSubmit,
}) => {
  const classes = useStyles()
  const [formError, setFormError] = useState(error)
  const [creditCard, setCreditCard] = useState()
  const creditCardRef = useRef()
  const initialValues = { name, number, expiry, cvc }

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setSubmitting }
  ) => {
    try {
      setFormError(error)
      await onSubmit(values)
      resetForm(initialValues)
    } catch (err) {
      if (err.form) setFormError(err.form)
      if (err.fields) setErrors(err.fields)
      if (!err.form && !err.fields) {
        setFormError('Error processing')
      }
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => setFormError(error), [error])

  useEffect(() => {
    if (creditCard) {
      return
    }

    const cc = new CreditCard({
      form: 'form',
      container: creditCardRef.current,
      placeholders: {
        number: '•••• •••• •••• ••••',
        name: name || 'Full Name',
        expiry: '••/••',
        cvc: '•••',
      },
    })

    setCreditCard(cc)
  }, [creditCardRef.current])

  return (
    <>
      {formError && (
        <Typography color="error" gutterBottom>
          {formError}
        </Typography>
      )}

      <div
        id="card-wrapper"
        className={classes.creditCard}
        ref={creditCardRef}
      />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={PaymentSchema}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Card Number"
                  name="number"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Expiry"
                  name="expiry"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth variant="filled" label="CVC" name="cvc" />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Cardholder Name"
                  name="name"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.submit}
                  color="primary"
                  disabled={!isValid || isSubmitting || loading}
                  fullWidth
                  loading={loading}
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default PaymentForm
