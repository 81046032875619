import { Paper, makeStyles } from '@material-ui/core'
import { Calendar as MuiCalendar, Day } from '@material-ui/pickers'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  calendarWrap: {
    overflow: 'hidden',
  },
  date: {
    position: 'relative',
  },
  hasEvent: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: '50%',
    content: '""',
    height: 34,
    left: 3,
    position: 'absolute',
    top: 1,
    width: 34,
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: theme.spacing(1),
  },
}))

export const Calendar = ({ selectedDates = [], className, ...props }) => {
  const classes = useStyles()
  return (
    <Paper className={classNames([classes.root, className])}>
      <div className={classes.calendarWrap}>
        <MuiCalendar
          renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
            const hasEvent = selectedDates.some(d => d.isSame(day, 'date'))
            return (
              <div className={classes.date}>
                {hasEvent && <div className={classes.hasEvent} />}
                {dayComponent}
              </div>
            )
          }}
          {...props}
        />
      </div>
    </Paper>
  )
}

Calendar.propTypes = {
  selectedDates: PropTypes.array,
}

export default Calendar
