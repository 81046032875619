import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

export const FormikTextField = ({ field, form, ...props }) => {
  const { touched, errors } = form
  const { name } = field
  return (
    <TextField
      {...props}
      {...field}
      error={Boolean(touched[name] && errors[name])}
      helperText={touched[name] && errors[name]}
      inputProps={props}
      variant="filled"
    />
  )
}

FormikTextField.propTypes = {
  // `field` and `form` passed in from Formik
  // - https://github.com/jaredpalmer/formik/blob/master/src/Field.tsx
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default FormikTextField
