import {
  Button,
  Card,
  CardContent,
  Link,
  Typography,
  makeStyles,
} from '@material-ui/core'
import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from '@reach/router'

import { Heading } from '../typography'

const useStyles = makeStyles(theme => ({
  compareButton: {
    marginTop: theme.spacing(2),
  },
  points: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1.5),
  },
  root: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 500,
    },
  },
  tierInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

const formatDate = dte => moment(dte).format('DD/MM/YYYY')

export const MembershipCard = ({
  className,
  expiry,
  name,
  nextTier,
  number,
  points,
  tier,
  tierExpiry,
}) => {
  const classes = useStyles()

  return (
    <Card className={classNames(classes.root, className)}>
      <CardContent className={classes.content}>
        <div className={classes.memberDetails}>
          <Heading variant="h2">{name}</Heading>
          <Typography color="textSecondary">No. {number}</Typography>
          {tier && (
            <div className={classes.tierInfo}>
              <Typography>{tier.name} Tier Member</Typography>
              <Typography>Expires {formatDate(tierExpiry)}</Typography>
            </div>
          )}
          <Typography className={classes.points}>
            {formatNum(points)} Renewal Points
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}

const formatNum = numStr => numStr.toLocaleString()

MembershipCard.propTypes = {
  className: PropTypes.string,
  expiry: PropTypes.string,
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  tier: PropTypes.exact({
    id: PropTypes.string,
    level: PropTypes.number,
    name: PropTypes.string,
  }),
  tierExpiry: PropTypes.string,
}

export default MembershipCard
