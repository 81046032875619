import { Box, Typography, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'
import React from 'react'

import { useProfile } from 'novus-common/lib/hooks'

import { Heading } from '../../typography'

const useStyles = makeStyles(theme => ({
  qrContainer: {
    backgroundColor: '#fff',
    color: '#000',
    fontSize: 18,
    textAlign: 'center',
  },
}))

export const RegisteredDetails = ({
  eventId,
  eventTitle,
  memberGuests,
  memberNumber,
}) => {
  const classes = useStyles()

  const QRValue = JSON.stringify({
    eventId,
    eventTitle,
    memberGuests,
    memberNumber,
    totalTickets: memberGuests + 1,
  })

  return (
    <>
      <Box pb={2}>
        <Heading variant="h3" underlined={true}>
          Registration
        </Heading>
      </Box>
      <Box pb={4}>
        <Box p={4} className={classes.qrContainer}>
          <Box pb={2} justifyContent="center" display="flex">
            <QRCode value={QRValue} size={180} />
          </Box>
          <Box pb={2}>
            <strong>{memberNumber}</strong>
          </Box>
          <Box>
            <strong>{eventTitle}</strong>
          </Box>
          <Box>
            <strong>Additional Guests:</strong> {memberGuests}
          </Box>
          <Box>
            <strong>Total Attendants:</strong> {memberGuests + 1}
          </Box>
        </Box>
      </Box>
    </>
  )
}

RegisteredDetails.propTypes = {
  eventId: PropTypes.string,
  eventTitle: PropTypes.string,
  memberGuests: PropTypes.number,
  memberNumber: PropTypes.string,
}

export default RegisteredDetails
