import React from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import Heading from '../typography/Heading'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(6),
    },
  },
  heading: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
}))

export default ({ className, title, children, ...props }) => {
  const classes = useStyles()

  return (
    <section className={classNames(classes.root, className)} {...props}>
      {title && (
        <Heading
          variant="h2"
          className={classes.heading}
          gutterBottom
          underlined
        >
          {title}
        </Heading>
      )}
      {children}
    </section>
  )
}
