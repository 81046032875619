import { Typography, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
  bigGutter: {
    marginBottom: theme.spacing(3),
  },
  h1: {
    fontFamily: '"Oswald", "Helvetica", serif',
    fontSize: '1.75rem',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.25rem',
    },
  },
  h2: {
    fontFamily: '"Oswald", "Helvetica", serif',
    fontSize: '1.5rem',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.875rem',
    },
  },
  h3: {
    fontFamily: '"Oswald", "Helvetica", serif',
    fontSize: '1.15rem',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.45rem',
    },
  },
  underlined: {
    paddingBottom: theme.spacing(2.5),
    position: 'relative',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(3.5),
    },

    '&:after': {
      backgroundColor: theme.palette.primary.main,
      bottom: 0,
      borderRadius: 5,
      content: '""',
      height: 4,
      marginLeft: -30,
      left: '50%',
      position: 'absolute',
      width: 60,
      [theme.breakpoints.up('sm')]: {
        marginLeft: -40,
        width: 80,
      },
    },
  },
}))

export const Heading = ({
  bigGutter,
  className,
  underlined,
  variant,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Typography
      className={classNames(classes[variant], className, {
        [classes.underlined]: underlined,
        [classes.bigGutter]: bigGutter,
      })}
      variant={variant}
      {...props}
    />
  )
}

Heading.propTypes = {
  className: PropTypes.string,
  bigGutter: PropTypes.bool,
  underlined: PropTypes.bool,
  variant: PropTypes.string,
}

export default Heading
