import {
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { TextField } from 'formik-material-ui'

import PhoneInputFormik from './PhoneInputFormik'
import Button from '../form/Button'
import Heading from '../typography/Heading'
import PasswordFieldFormik from './PasswordFieldFormik'

const rePhoneNumber = /^\+\d{0,4}\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  phoneNumber: Yup.string()
    .required('Required')
    .matches(
      rePhoneNumber,
      'Please enter valid phone number with country code (e.g. +44)'
    ),
  password: Yup.string()
    .min(8)
    .required('Required'),
})

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
  },
  linkArea: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1.5),
  },
  termsLink: {
    color: 'inherit',
    textDecoration: 'underline',
  },
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
}))

export const SignUpForm = ({
  firstName = '',
  lastName = '',
  email = '',
  phoneNumber = '',
  password = '',
  loading = false,
  error,
  onSubmit,
  onBackClick,
}) => {
  const classes = useStyles()
  const [formError, setFormError] = useState(error)
  const initialValues = { email, lastName, firstName, phoneNumber, password }

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setSubmitting }
  ) => {
    try {
      setFormError(error)
      await onSubmit(values)
      resetForm(values)
    } catch (err) {
      if (err.form) setFormError(err.form)
      if (err.fields) setErrors(err.fields)
      if (!err.form && !err.fields) {
        setFormError('Error processing')
      }
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => setFormError(error), [error])

  return (
    <Card>
      <CardContent>
        <Heading bigGutter variant="h3" className={classes.title}>
          Sign Up
        </Heading>
        {formError && (
          <Typography color="error" gutterBottom>
            {formError}
          </Typography>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={SignUpSchema}
        >
          {({ handleSubmit, isSubmitting, isValid }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="filled"
                    label="First Name"
                    name="firstName"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Last Name"
                    name="lastName"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Email"
                    name="email"
                    type="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInputFormik
                    defaultCountry="ae"
                    fullWidth
                    variant="filled"
                    label="Phone"
                    name="phoneNumber"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={PasswordFieldFormik}
                    fullWidth={true}
                    label="Password"
                    name="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    By clicking "Sign Up" you agree to our{' '}
                    <Link
                      href="/privacy"
                      target="_blank"
                      className={classes.termsLink}
                    >
                      Privacy Policy
                    </Link>{' '}
                    and{' '}
                    <Link
                      href="/terms"
                      target="_blank"
                      className={classes.termsLink}
                    >
                      Terms & Conditions
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className={classes.submit}
                    color="primary"
                    disabled={!isValid || isSubmitting}
                    fullWidth
                    loading={loading}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                  >
                    Sign Up
                  </Button>
                </Grid>
              </Grid>
              <div />
            </Form>
          )}
        </Formik>

        <div className={classes.linkArea}>
          <Link className={classes.link} onClick={onBackClick}>
            Back to sign in
          </Link>
        </div>
      </CardContent>
    </Card>
  )
}

export default SignUpForm
