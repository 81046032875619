import React, { useState } from 'react'
import { Container, Grid, Typography, makeStyles } from '@material-ui/core'
import { useApp, useApi } from 'novus-common/lib/hooks'

import DialogWithClose from '../../dialogs/DialogWithClose'
import Button from '../../form/Button'
import CardLast4 from '../../CardLast4'
import Section from '../../layout/Section'
import Heading from '../../typography/Heading'
import EventRegistrationForm, {
  formatPrice,
} from '../../form/EventRegistrationForm'

const useStyles = makeStyles(theme => ({
  bottomMargin: {
    display: 'block',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingBottom: theme.spacing(3),
  },
}))

const plural = (guests, text) =>
  guests == 1 ? `1 ${text}` : `${guests} ${text}s`

export default ({ data, onSuccess, onClose, ...props }) => {
  const [guests, setGuests] = useState(0)
  const [response, setRequest, loading, error, reset] = useApi({
    disableAppLoader: true,
    onSuccess: response => {
      displaySnackMessage('Successfully registered for event!', 'success')
      onSuccess && onSuccess(response)
    },
  })
  const { displaySnackMessage } = useApp()
  const classes = useStyles()
  const {
    id,
    guestsPaidCost,
    cancellationPeriod,
    noShowFee,
    personalPaidCost,
  } = data

  const handleSubmit = ({ guests }) => {
    if (guests < 0) {
      return
    }

    setGuests(guests)

    setRequest({
      method: 'post',
      path: `/events/${id}/register/`,
      init: {
        response: true,
        body: { guests },
      },
    })
  }

  const handleCloseClick = () => {
    if (loading) {
      return
    }

    reset()
    onClose && onClose()
  }

  const CancellationText = () =>
    !!noShowFee && (
      <Typography className={classes.bottomMargin} variant="subtitle">
        Cancellation: Please note you may cancel registration up to
        {cancellationPeriod % 7
          ? `${cancellationPeriod} days `
          : `${cancellationPeriod / 7} week `}
        in advance. Non-attendance carries a {formatPrice(noShowFee)} fee.
      </Typography>
    )

  const renderForm = () => (
    <>
      <Heading variant="h3" className={classes.bottomMargin}>
        Register For An Event
      </Heading>
      <EventRegistrationForm
        {...data}
        guestsPaidCost={Number(guestsPaidCost)}
        loading={loading}
        noShowFee={Number(noShowFee)}
        onSubmit={handleSubmit}
        personalPaidCost={Number(personalPaidCost)}
      />
    </>
  )

  const renderSuccess = () => (
    <>
      <Heading variant="h3" className={classes.bottomMargin}>
        Registration Complete
      </Heading>
      <Typography className={classes.bottomMargin}>
        You are registered{' '}
        {guests > 0 && `with ${plural(guests, 'additional guest')}`}!
      </Typography>
      <CancellationText />
      <Button color="primary" onClick={handleCloseClick} variant="contained">
        Close
      </Button>
    </>
  )

  return (
    <DialogWithClose
      {...props}
      className={classes.root}
      maxWidth="xs"
      onClose={onClose}
    >
      {response ? renderSuccess() : renderForm()}
    </DialogWithClose>
  )
}
