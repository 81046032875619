import React from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useProfile } from 'novus-common/lib/hooks'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0),
  },
}))

export default ({ className }) => {
  const [profile] = useProfile()
  const classes = useStyles()

  if (!profile) {
    return null
  }

  const { paymentMethod } = profile

  const render = content => (
    <div className={classNames(classes.root, className)}>{content}</div>
  )

  if (!paymentMethod) {
    return render('No payment method found')
  }

  return render(
    `[${paymentMethod.card.brand}] ending ${paymentMethod.card.last4}`
  )
}
