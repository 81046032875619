import React, { useEffect, useState } from 'react'
import {
  Tab,
  Tabs,
  Card,
  CardContent,
  Grid,
  makeStyles,
} from '@material-ui/core'
import { parsers } from 'novus-common/lib/modules/api'
import { useProfile, useApiGetWithProfileData } from 'novus-common/lib/hooks'
import { a11yProps } from './TabPanel'
import AvailablePlans from './AvailablePlans'
import SubscribedPlans from './SubscribedPlans'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '100px',
    },
  },
}))

const SelectPlans = ({ onSuccess }) => {
  // TODO onSuccess -> onChange
  const classes = useStyles()
  const [profile, setProfile] = useProfile()
  const [plans] = useApiGetWithProfileData({
    path: '/benefits/additional/',
    parse: parsers.parseAdditionalBenefits,
  })
  const [availablePlans, setAvailablePlans] = React.useState()
  const [subscribedPlans, setSubscribedPlans] = React.useState()
  const [value, setValue] = React.useState(0)

  const handleTabsChange = (_, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (!profile || !plans) {
      return
    }

    const { subscribedBenefits } = profile
    const subscribedBenefitIds = subscribedBenefits.reduce(
      (ids, { benefit }) => ({ ...ids, [benefit.id]: true }),
      {}
    )
    const isSubscribed = ({ id }) => subscribedBenefitIds[id]
    const availablePlans = plans.filter(plan => !isSubscribed(plan))
    const subscribedPlans = plans.filter(isSubscribed)

    setAvailablePlans(availablePlans)
    setSubscribedPlans(subscribedPlans)
  }, [profile, plans])

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tabs
                aria-label="select plans"
                centered
                indicatorColor="primary"
                onChange={handleTabsChange}
                value={value}
              >
                <Tab label="Available" {...a11yProps(0)} />
                <Tab label="Subscribed" {...a11yProps(1)} />
              </Tabs>

              {(!availablePlans || !subscribedPlans) && 'Loading...'}

              {availablePlans && (
                <AvailablePlans
                  plans={availablePlans}
                  value={value}
                  index={0}
                  onSuccess={onSuccess}
                />
              )}

              {subscribedPlans && (
                <SubscribedPlans
                  plans={subscribedPlans}
                  value={value}
                  index={1}
                  onSuccess={onSuccess}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default SelectPlans
