import React from 'react'
import { Container } from '@material-ui/core'
import { Match, useNavigate } from '@reach/router'
import { parsers } from 'novus-common/lib/modules/api'
import randomize from 'randomatic'
import { useProfile, useApiGet } from 'novus-common/lib/hooks'
import OfferingDetailCard from '../../cards/OfferingDetailCard'
import CancellationDialog from './CancellationDialog'
import RegisteredDetails from './RegisteredDetails'
import RegistrationDialog from './RegistrationDialog'
import SetupPaymentCardDialog from './SetupPaymentCardDialog'

const Event = ({ id, uri, navigate }) => {
  const path = `/events/${id}/`
  const [event, setPath] = useApiGet({
    path,
    parse: parsers.parseEvent,
  })
  const [profile] = useProfile()

  const refetchData = () => {
    setPath(`${path}?${randomize('A', 5)}`)
  }
  const nav = useNavigate()

  const handleRegisterClick = () => {
    let action

    switch (true) {
      case !event.memberRegistered && !!paymentMethod:
        action = 'register'
        break
      case event.memberRegistered:
        action = 'cancel'
        break
      case !paymentMethod:
        action = 'setup-payment-card'
        break
    }

    navigate(`${uri}/${action}`)
  }

  const handleCloseClick = () => {
    navigate(uri)
  }

  const handleRegistrationSuccess = () => {
    refetchData()
  }

  const handleCancellationSuccess = () => {
    refetchData()
  }

  const handleSetupPaymentCardSuccess = () => {
    refetchData()
    navigate(`${uri}/register`)
  }

  if (!event || !profile) {
    return null
  }

  const { number, paymentMethod } = profile
  const { memberGuests, memberRegistered, title } = event

  return (
    <Container maxWidth="sm">
      <OfferingDetailCard
        {...event}
        isEvent={true}
        onClickBack={() => nav(-1)}
        onRegisterClick={handleRegisterClick}
        registeredInfo={
          memberRegistered && (
            <RegisteredDetails
              eventId={id}
              eventTitle={title}
              memberGuests={memberGuests}
              memberNumber={number}
            />
          )
        }
      />

      <Match path="register">
        {({ match }) => (
          <RegistrationDialog
            open={!!match}
            onClose={handleCloseClick}
            onSuccess={handleRegistrationSuccess}
            data={event}
          />
        )}
      </Match>
      <Match path="cancel">
        {({ match }) => (
          <CancellationDialog
            open={!!match}
            onClose={handleCloseClick}
            onSuccess={handleCancellationSuccess}
            data={event}
          />
        )}
      </Match>
      <Match path="setup-payment-card">
        {({ match }) => (
          <SetupPaymentCardDialog
            open={!!match}
            onClose={handleCloseClick}
            onSuccess={handleSetupPaymentCardSuccess}
            data={event}
          />
        )}
      </Match>
    </Container>
  )
}

export default Event
