import React from 'react'
import { Container } from '@material-ui/core'
import { navigate } from '@reach/router'
import { Auth } from 'aws-amplify'
import { useApp } from 'novus-common/lib/hooks'
import ChangePasswordForm from '../form/ChangePasswordForm'
import AccountHeading from '../typography/AccountHeading'

const ChangePassword = () => {
  const { displaySnackMessage } = useApp()

  const handleSubmit = async ({ oldPassword, newPassword }) => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, oldPassword, newPassword)
      displaySnackMessage('Password changed!', 'success')
      navigate('/account')
    } catch (error) {
      throw { form: error.message }
    }
  }

  return (
    <Container maxWidth="xs">
      <AccountHeading>Change Password</AccountHeading>

      <ChangePasswordForm onSubmit={handleSubmit} />
    </Container>
  )
}

export default ChangePassword
