import {
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  ArrowBack as ArrowBackIcon,
  CalendarToday as CalendarTodayIcon,
  Done as DoneIcon,
  StarBorder as StarBorderIcon,
} from '@material-ui/icons'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import LoadButton from '../form/Button'
import { Heading } from '../typography'
import CapacityChip from './chips/CapacityChip'
import RegisteredChip from './chips/RegisteredChip'

const useStyles = makeStyles(theme => ({
  back: {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 25,
    left: theme.spacing(1),
    position: 'absolute',
    top: theme.spacing(1),
    zIndex: 1,
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  calendarIcon: {
    fontSize: '1rem',
    marginRight: theme.spacing(1) / 2,
    position: 'relative',
    top: 2,
  },
  description: {
    marginTop: theme.spacing(2),
    whiteSpace: 'pre-line',
  },
  infoArea: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  interestArea: {
    marginBottom: theme.spacing(2),
  },
  media: {
    position: 'relative',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  meta: {
    marginBottom: theme.spacing(2),
  },
  partner: {
    fontSize: '0.875rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
  root: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  starIcon: {
    fontSize: '1rem',
    marginRight: theme.spacing(1) / 2,
    position: 'relative',
    top: 3,
  },
}))

export const OfferingDetailCard = ({
  capacity,
  category = {},
  badTier,
  className,
  date,
  description,
  image,
  eventImages,
  isEvent,
  memberRegistered,
  onClickBack,
  registerInterestLoading = false,
  registrationCount,
  onRegisterClick,
  partner,
  registeredInfo,
  title,
  tier,
}) => {
  const classes = useStyles()
  const images = [{ image }, ...eventImages]

  return (
    <Card className={classNames(classes.root, className)}>
      <CardMedia className={classes.media}>
        <CarouselProvider
          naturalSlideWidth={536}
          naturalSlideHeight={350}
          totalSlides={eventImages.length + 1}
          infinite
          isPlaying
        >
          <Slider>
            {images.map(({ image }, i) => (
              <Slide
                key={i}
                index={i}
                className={classes.image}
                style={{
                  backgroundImage: `url(${image})`,
                }}
              />
            ))}
          </Slider>
        </CarouselProvider>
        {onClickBack && (
          <IconButton
            className={classes.back}
            onClick={onClickBack}
            variant="contained"
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        {memberRegistered && <RegisteredChip />}
        {!!capacity && (
          <CapacityChip
            capacity={capacity}
            registrationCount={registrationCount}
          />
        )}
      </CardMedia>
      <CardContent>
        <div className={classes.infoArea}>
          <div>
            <Heading variant="h3">{title}</Heading>
            <Typography
              variant="body2"
              gutterBottom={tier || date}
              className={classes.partner}
            >
              {isEvent ? category.name : partner}
            </Typography>
            <div className={classes.meta}>
              {tier && (
                <Typography variant="body1">
                  <StarBorderIcon className={classes.starIcon} />
                  {tier}
                </Typography>
              )}
              {date && (
                <Typography variant="body1">
                  <CalendarTodayIcon className={classes.calendarIcon} />
                  {date}
                </Typography>
              )}
            </div>
          </div>
          <div className={classes.interestArea}>
            {badTier ? (
              <Button
                color="primary"
                disabled={true}
                fullWidth={true}
                variant="contained"
              >
                {tier} tier members only
              </Button>
            ) : isEvent ? (
              <InterestRegisterButton
                capacity={capacity}
                fullWidth={true}
                memberRegistered={memberRegistered}
                loading={registerInterestLoading}
                onRegisterClick={onRegisterClick}
                registrationCount={registrationCount}
                variant="contained"
              />
            ) : null}
          </div>
        </div>
        {registeredInfo}
        <Heading variant="h3" underlined={true}>
          Description
        </Heading>
        <Typography className={classes.description}>{description}</Typography>
      </CardContent>
    </Card>
  )
}

const InterestRegisterButton = ({
  capacity,
  memberRegistered,
  onRegisterClick,
  registrationCount,
  ...props
}) => {
  const color = memberRegistered ? 'default' : 'primary'
  if (!memberRegistered && !!capacity && registrationCount == capacity) {
    return (
      <Button {...props} color="primary" disabled={true}>
        Event Sold Out
      </Button>
    )
  } else {
    return (
      <LoadButton onClick={onRegisterClick} color={color} {...props}>
        {memberRegistered ? 'Cancel Registration' : 'Register'}
      </LoadButton>
    )
  }
}

OfferingDetailCard.propTypes = {
  badTier: PropTypes.bool,
  capacity: PropTypes.number,
  category: PropTypes.shape({
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  isEvent: PropTypes.bool,
  memberRegistered: PropTypes.bool,
  registerInterestLoading: PropTypes.bool,
  registrationCount: PropTypes.number,
  onClickBack: PropTypes.func,
  onRegisterClick: PropTypes.func.isRequired,
  partner: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tier: PropTypes.string,
}

export default OfferingDetailCard
