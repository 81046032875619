import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  themeName: 'Novus Web',
  palette: {
    background: {
      default: '#1E1E1E',
      paper: '#454545',
    },
    primary: {
      contrastText: '#fff',
      grey: '#C5C5C5',
      main: '#C5A380',
    },
    error: {
      main: '#ff1a1a',
    },
    text: {
      primary: '#fff',
      secondary: '#C5C5C5',
    },
    type: 'dark',
  },
  typography: {
    fontFamily: '"Questrial", "Helvetica", "Arial", sans-serif',
  },
})

theme.overrides = {
  MuiButton: {
    contained: {
      backgroundColor: '#9B9B9B',
      boxShadow: 'none',
      color: '#fff',
      '&:hover': {
        boxShadow: 'none',
      },
    },
    label: {},
    outlined: {
      borderColor: '#fff',
      borderRadius: 25,
      borderWidth: 3,
      '&$disabled': {
        borderWidth: 3,
      },
    },
    outlinedPrimary: {
      borderColor: theme.palette.primary.main,
      borderWidth: 3,
      '&:hover': {
        borderWidth: 3,
      },
      '&$disabled': {
        borderWidth: 3,
      },
    },
    root: {
      fontFamily: '"Oswald", "Helvetica", serif',
    },
  },
  MuiPickersDay: {
    daySelected: {
      backgroundColor: 'transparent',
    },
    current: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: '#fff',
    },
  },
}

export default theme
