import React from 'react'
import { Container, Link, makeStyles } from '@material-ui/core'

import Heading from '../typography/Heading'

const useStyles = makeStyles(theme => ({
  heading: {
    paddingTop: theme.spacing(3),
  },
}))

const Contact = () => {
  const classes = useStyles()
  return (
    <Container maxWidth="md">
      <Heading
        bigGutter={true}
        underlined
        variant="h2"
        className={classes.heading}
      >
        Contact Us
      </Heading>
      <div>
        <p>
          If you have any questions, queries or issues please contact us at{' '}
          <Link href="mailto:support@novus-ba.com">support@novus-ba.com</Link>.
        </p>
      </div>
    </Container>
  )
}

export default Contact
