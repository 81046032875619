import React from 'react'
import { Container } from '@material-ui/core'
import { RequireNewPassword as BaseRequireNewPassword } from 'aws-amplify-react'
import { Hub } from 'aws-amplify'
import RequireNewPasswordForm from '../form/RequireNewPasswordForm'
import AuthHeader from '../layout/AuthHeader'
import createInputChangeEvent from './createInputChangeEvent'

const initialState = {
  error: null,
}

class RequireNewPassword extends BaseRequireNewPassword {
  constructor(props) {
    super(props)

    this._validAuthStates = ['requireNewPassword']
    this.state = {
      ...this.state,
      ...initialState,
    }
  }

  componentDidMount = () => {
    Hub.listen('auth', this.handleAuthEvent)
  }

  componentWillUnmount = () => {
    Hub.remove('auth', this.handleAuthEvent)
  }

  handleAuthEvent = ({ payload }) => {
    this.setState({
      error:
        payload.event === 'completeNewPassword_failure' ? payload.data : null,
    })
  }

  handleSubmit = data => {
    Object.entries(data)
      .map(createInputChangeEvent)
      .forEach(this.handleInputChange)

    this.change()
  }

  handleBackClick = () => {
    this.changeState('signIn')
    this.setState(initialState)
  }

  showComponent = () => (
    <Container maxWidth="xs">
      <AuthHeader />
      <RequireNewPasswordForm
        error={this.state.error && { form: this.state.error.message }}
        onSubmit={this.handleSubmit}
        onBackClick={this.handleBackClick}
      />
    </Container>
  )
}

export default RequireNewPassword
