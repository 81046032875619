import React from 'react'
import { Container } from '@material-ui/core'
import { SignIn as BaseSignIn } from 'aws-amplify-react'
import { Hub } from 'aws-amplify'
import { navigate } from '@reach/router'
import SignInForm from '../form/SignInForm'
import AuthHeader from '../layout/AuthHeader'
import createInputChangeEvent from './createInputChangeEvent'

const initialState = {
  error: null,
}

class SignIn extends BaseSignIn {
  constructor(props) {
    super(props)

    this._validAuthStates = ['signIn', 'signedOut', 'signedUp']
    this.state = {
      ...this.state,
      ...initialState,
    }
  }

  componentDidMount = () => {
    Hub.listen('auth', this.handleAuthEvent)
  }

  componentWillUnmount = () => {
    Hub.remove('auth', this.handleAuthEvent)
  }

  handleAuthEvent = ({ payload }) => {
    this.setState({
      error: payload.event === 'signIn_failure' ? payload.data : null,
    })
  }

  handleSubmit = data => {
    Object.entries(data)
      .map(createInputChangeEvent)
      .forEach(this.handleInputChange)

    return this.signIn()
  }

  handleForgotPasswordClick = () => {
    this.changeState('forgotPassword')
    this.setState(initialState)
  }

  handleSignUpClick = () => {
    this.setState(initialState)
    navigate('/sign-up')
  }

  showComponent = () => (
    <Container maxWidth="xs">
      <AuthHeader />
      <SignInForm
        error={this.state.error && { form: this.state.error.message }}
        onSubmit={this.handleSubmit}
        onForgotPasswordClick={this.handleForgotPasswordClick}
        onSignUpClick={this.handleSignUpClick}
      />
    </Container>
  )
}

export default SignIn
